import React from "react";
import {
    FormContainer,
    FormItem,
    InputCpf,
    InputPhone,
    useFormApi,
} from "rk-react-component";
import { Form, Input } from "antd";
import { UserModel } from "../../../@types/user";
import { api } from "../../../services/api";

interface FormUserProps {
    onFinish: (data: UserModel) => void;
    initialValues?: UserModel;
    onBack?: () => void;
    children?: any;
}

export const FormUser = ({
  onFinish,
  initialValues,
  onBack,
  children,
}: FormUserProps) => {
  const [form] = Form.useForm();
  const { save, loading } = useFormApi<any, any>({
    url: "/users",
    id: initialValues?.id,
    api,
  });
  return (
    <FormContainer
        labelCol={{ span: 24 }}
      name={"form_user"}
      initialValues={initialValues}
      form={form}
      onFinish={(data) => {
        save(
          data,
          (response) => {
            onFinish(response);
          },
          (e) => console.warn(e),
        );
      }}
      loading={loading}
      onBack={onBack}
    >
      <FormItem
        label={"Nome"}
        name={"name"}
        rules={[{ required: true, message: "O Nome é obrigatório" }]}
      >
        <Input placeholder={"Nome"} data-testid={"name"} />
      </FormItem>
      <FormItem
        label={"Email"}
        name={"email"}
        rules={[{ required: true, message: "O Email é obrigatório" }]}
      >
        <Input placeholder={"Email"} data-testid={"email"} />
      </FormItem>
      <FormItem
        label={"CPF"}
        name={"document"}
        rules={[{ required: true, message: "O CPF é obrigatório" }]}
      >
        <InputCpf placeholder={"CPF"} data-testid={"document"} />
      </FormItem>
      <FormItem
        label={"Celular"}
        name={"mobile"}
        rules={[{ required: true, message: "O Celular é obrigatório" }]}
      >
        <InputPhone placeholder={"Celular"} data-testid={"mobile"} />
      </FormItem>
      <FormItem
        label={"Telefone"}
        name={"phone"}
      >
        <InputPhone placeholder={"Telefone"} data-testid={"phone"} />
      </FormItem>
      <FormItem label={"Instagram"} name={"instagram"}>
        <Input placeholder={"Instagram"} data-testid={"instagram"} />
      </FormItem>
      <FormItem label={"Facebook"} name={"facebook"}>
        <Input placeholder={"Facebook"} data-testid={"facebook"} />
      </FormItem>
      <FormItem label={"Site"} name={"site"}>
        <Input placeholder={"site"} data-testid={"site"} />
      </FormItem>
      <FormItem label={"Linkedin"} name={"linkedin"}>
        <Input placeholder={"Linkedin"} data-testid={"linkedin"} />
      </FormItem>
      {!initialValues?.id ? (
        <FormItem
          label={"Senha"}
          name={"password"}
          rules={[{ required: true, message: "A Senha é obrigatória" }]}
        >
          <Input.Password placeholder={"Senha"} data-testid={"password"} />
        </FormItem>
      ) : null}
      {children}
    </FormContainer>
  );
};
