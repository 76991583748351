import React from "react";
import "./style.css";
import { TableContainer } from "../table-container/table-container";
import { CompanyModel } from "../../../@types/company";
import {
    helpColumnBoolean,
    helpColumnCreatedAt,
} from "../../columns/column-helper";

interface TableCompanyProps {
    dataSource: CompanyModel[];
    loading: boolean;
    onEdit?: (record: CompanyModel) => void;
    pagination?: any;
    loadData?: any;
}

export const TableCompany = ({dataSource, loading, onEdit, pagination, loadData}: TableCompanyProps) => {

    return (
        <TableContainer
            loadData={loadData}
            id={"table_company"}
            loading={loading}
            pagination={pagination}
            onEdit={onEdit}
            columns={[
                {dataIndex: "name", key: "name", title: "Nome"},
                helpColumnBoolean({field: 'has_volunter_program', title: 'Programa de Voluntáriado?', tooltip: 'Já tem programa de voluntariado corporativo?'}),
                helpColumnBoolean({field: 'has_esg_program', title: 'ESG?', tooltip: 'Possui programa de ESG?'}),
                helpColumnBoolean({field: 'has_social_responsibility_policy', title: 'Responsabilidade Social?', tooltip: 'Possui programa de Responsabilidade Social?'}),
                helpColumnCreatedAt(),
            ]}
            dataSource={dataSource}
        />
    );
};
