import React from "react";
import { Form, Input } from "antd";
import { FormFilterContainer, FormFilterItem } from "rk-react-component";

interface FormFilterPartyProps {
    onFinish: (data: any) => void;
    initialValues?: any;
}

export const FormFilterVolunteer = ({
    onFinish,
    initialValues,
}: FormFilterPartyProps) => {
    const [form] = Form.useForm();
    return (
        <FormFilterContainer
            formProps={{ onFinish, initialValues }}
            form={form}
            hiddenShowMore={true}
        >
            {() => (
                <>
                    <FormFilterItem name={"user_name"}>
                        <Input
                            placeholder={"Nome do usuário"}
                            data-testid="user_name"
                        />
                    </FormFilterItem>
                    <FormFilterItem name={"user_email"}>
                        <Input
                            placeholder={"Email do usuário"}
                            data-testid="user_email"
                        />
                    </FormFilterItem>
                </>
            )}
        </FormFilterContainer>
    );
};
