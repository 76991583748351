import { useContext, useMemo } from "react";
import {
    routesCompanyManager,
    routesDefault,
    routesMaster,
    routesOscManager,
} from "../main/routes-role";
import { MeContext } from "../contexts/me-context/me-context";

export const useRouteRole = () => {
    const { role } = useContext(MeContext);

    const routes = useMemo(() => {
        if (role?.role === "MASTER") return routesMaster();
        if (role?.role === "COMPANY_MANAGER") return routesCompanyManager();
        if (role?.role === "OSC_MANAGER") return routesOscManager();
        return routesDefault();
    }, [role]);

    return { routes };
};
