import React from "react";
import { Route, Routes } from "react-router-dom";
import {
    company,
    config_option,
    dashboardCompany,
    dashboardMaster,
    dashboardOsc,
    ong,
    order,
    profile,
    user,
    volunteer,
    volunteer_master,
} from "./routes-modules";
import { MyCompanyPage } from "../pages/company/my-company-page/my-company-page";

export const routesMaster = () => {
    return {
        location: [
            dashboardMaster.location,
            user.location,
            company.location,
            ong.location,
            config_option.location,
            volunteer_master.location,
        ],
        routes: (
            <Routes>
                {dashboardMaster.routes}
                {user.routes}
                {config_option.routes}
                {profile.routes}
                {company.routes}
                {ong.routes}
                {volunteer_master.routes}
            </Routes>
        ),
    };
};


export const routesCompanyManager = () => {
    return {
        location: [
            dashboardCompany.location,
            volunteer.location
        ],
        routes: (
            <Routes>
                <Route path={'/my-company'} element={<MyCompanyPage/>}/>
                {profile.routes}
                {dashboardCompany.routes}
                {volunteer.routes}
            </Routes>
        ),
    };
};

export const routesOscManager = () => {
    return {
        location: [
            dashboardOsc.location,
            order.location
        ],
        routes: (
            <Routes>
                {profile.routes}
                <Route path={'/my-company'} element={<MyCompanyPage/>}/>
                {dashboardOsc.routes}
                {order.routes}
            </Routes>
        ),
    };
};

export const routesDefault = () => {
    return {
        location: [],
        routes: (<Routes/>),
    };
};
