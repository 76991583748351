import React, { useContext } from "react";
import { Form, Input, InputNumber } from "antd";
import { OrderModel } from "../../../@types/order";
import { FormContainer, FormItem, useFormApi } from "rk-react-component";
import { api } from "../../../services/api";
import { DatePickerMoment } from "../../../components/date-picke-moment/date-picker-moment";
import { MeContext } from "../../../contexts/me-context/me-context";
import { OrderStatusEnum } from "../../../@types/order-status-enum";
import { SelectStatusOrder } from "../../inputs/select-status-order/select-status-order";
import { SelectConfigOptions } from "../../inputs/select-config-options/select-config-options";

interface FormOrderProps {
    onFinish: (data: OrderModel) => void;
    initialValues: OrderModel | null;
    dateCast?: string[];
    onBack?: () => void;
    fixParams?: Record<string, any>;
}

export const FormOrder = ({
    onFinish,
    initialValues,
    dateCast,
    onBack,
    fixParams = {},
}: FormOrderProps) => {
    const [form] = Form.useForm();
    const status = Form.useWatch("status", form);
    const { role } = useContext(MeContext);
    const { save, loading } = useFormApi<any, any>({
        url: "/orders",
        id: initialValues?.id,
        fixParams,
        api,
    });
    //requester_company_id
    return (
        <FormContainer
            labelCol={{ span: 24 }}
            onBack={onBack}
            dateCast={dateCast}
            name={"form_order"}
            initialValues={{
                ...initialValues,
                status: initialValues?.status || OrderStatusEnum.DRAFT,
            }}
            form={form}
            onFinish={(data) => {
                save(
                    { ...data, requester_company_id: role?.allow_model_id },
                    (response) => {
                        onFinish(response);
                    },
                    (e) => console.warn(e),
                );
            }}
            loading={loading}
        >
            <FormItem
                label={"Título"}
                name={"title"}
                rules={[{ required: true, message: "O Título é obrigatório." }]}
            >
                <Input placeholder={"Nome"} />
            </FormItem>
            <FormItem
                label={"Descrição"}
                name={"description"}
                rules={[
                    { required: true, message: "A Descrição é obrigatória." },
                ]}
            >
                <Input.TextArea placeholder={"Descrição"} />
            </FormItem>
            <FormItem
                label={"Status"}
                name={"status"}
                rules={[{ required: true, message: "O Status é obrigatório." }]}
            >
                <SelectStatusOrder disabled style={{ width: "100%" }} />
            </FormItem>
            <FormItem
                label={"Habilidade"}
                name={"config_option_skill_id"}
                rules={[
                    { required: true, message: "A Habilidade é obrigatória." },
                ]}
            >
                <SelectConfigOptions
                    defaultOptions={initialValues?.config_option_skill}
                    type={"skill"}
                />
            </FormItem>
            <FormItem
                label={"Público"}
                name={"config_option_audience_id"}
                rules={[
                    { required: true, message: "O Público é obrigatório." },
                ]}
            >
                <SelectConfigOptions
                    defaultOptions={initialValues?.config_option_audience}
                    type={"publico"}
                />
            </FormItem>
            <FormItem
                label={"Data"}
                name={"date"}
                rules={[{ required: true, message: "A Data é obrigatória." }]}
            >
                <DatePickerMoment
                    format={"DD/MM/YYYY HH:mm"}
                    showTime
                    style={{ width: "100%" }}
                />
            </FormItem>
            <FormItem
                label={"Horas Previstas"}
                name={"expected_hours"}
                rules={[
                    {
                        required: true,
                        message: 'O campo "Horas Previstas" é obrigatório.',
                    },
                ]}
            >
                <InputNumber style={{ width: "100%" }} />
            </FormItem>
            <FormItem
                label={"Expectativa de pessoas atendidas/voluntário"}
                name={"expected_people"}
                rules={[
                    {
                        required: true,
                        message: 'O campo "Expectativa de pessoas atendidas/voluntário" é obrigatório.',
                    },
                ]}
            >
                <InputNumber style={{ width: "100%" }} />
            </FormItem>
            {status === OrderStatusEnum.FINISHED ? (
                <>
                    <FormItem
                        label={"Avaliação do Voluntário"}
                        name={"rating_by_company"}
                    >
                        <InputNumber min={0} max={5} />
                    </FormItem>
                    <FormItem
                        label={"Comentário da Avaliação"}
                        name={"rating_comment_by_company"}
                        tooltip={"Comentário da Avaliação feita pela OSC"}
                    >
                        <Input.TextArea />
                    </FormItem>
                </>
            ) : null}
        </FormContainer>
    );
};
