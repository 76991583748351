import React, { useState } from "react";
import { FormContainer, FormItem, SelectAction } from "rk-react-component";
import { Form, Upload } from "antd";
import { api } from "../../../services/api";

interface FormAddressProps {
    onFinish: (data: any) => void;
    onBack?: any;
    initialValues?: Partial<any>;
    modelId: string | number;
    modelType: string;
    type:
        | "documentos-da-empresa"
        | "documentos-da-osc"
        | "documentos-do-usuario";
}

export const FormDocument = ({
  onFinish,
  onBack,
  initialValues,
  modelId,
  modelType,
  type,
}: FormAddressProps) => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  return (
    <FormContainer
      labelCol={{ span: 24 }}
      onBack={onBack}
      loading={loading}
      name={"form_document"}
      initialValues={initialValues}
      form={form}
      onFinish={(data) => {
        setLoading(true);
        const formData = new FormData();
        formData.append(
          "file",
          data?.file_upload?.fileList?.[0]?.originFileObj,
        );
        formData.append("model_type", modelType);
        formData.append("model_id", modelId.toString());
        formData.append("name", "file");
        formData.append("description", "");
        formData.append("config_option_id", data?.config_option_id);
        api
          .post("/medias", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then(({ data: dataResponse }) => {
            onFinish(dataResponse);
          })
          .finally(() => {
            setLoading(false);
          });
      }}
    >
      <FormItem
        label={"Tipo do Documento"}
        name={"config_option_id"}
        rules={[{ required: true, message: "É necessário selecionar um tipo" }]}
      >
        <SelectAction
          url={"/config-options"}
          api={api}
          fixParams={{ type: type }}
        />
      </FormItem>
      <FormItem
        label={"Arquivo"}
        name={"file_upload"}
        rules={[
          { required: true, message: "É necessário carregar um arquivo" },
        ]}
      >
        <Upload maxCount={1} listType="picture-card" beforeUpload={() => false}>
          Upload
        </Upload>
      </FormItem>
    </FormContainer>
  );
};
