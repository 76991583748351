import { Button, Divider, Form, Space, Tooltip } from "antd";
import {
  CompanyIcon,
  MasterIcon,
  ONGIcon,
  VolunteerIcon,
} from "../../main/icons-list";
import React from "react";

export const SigninFastLogin = () => {
  const form = Form.useFormInstance();
  return (
    <Space
      size={0}
      split={<Divider type={"vertical"} />}
      style={{ marginBottom: 24, width: "100%", justifyContent: "center" }}
    >
      <Tooltip title={"MASTER"}>
        <Button
          type={"link"}
          icon={<MasterIcon />}
          onClick={() => {
            form.setFieldsValue({
              email: "admin@rockapps.com.br",
              password: "rockapps",
            });
          }}
        />
      </Tooltip>
      <Tooltip title={"COMPANY"}>
        <Button
          type={"link"}
          onClick={() => {
            form.setFieldsValue({
              email: "sambatech@gmail.com",
              password: "rockapps",
            });
          }}
          icon={<CompanyIcon />}
        />
      </Tooltip>
      <Tooltip title={"OSC"}>
        <Button
          type={"link"}
          icon={<ONGIcon />}
          onClick={() => {
            form.setFieldsValue({
              email: "vidaverde@gmail.com",
              password: "rockapps",
            });
          }}
        />
      </Tooltip>
      <Tooltip title={"VOLUNTEER"}>
        <Button
          type={"link"}
          icon={<VolunteerIcon />}
          onClick={() => {
            form.setFieldsValue({
              email: "volunteer1@rockapps.com",
              password: "rockapps",
            });
          }}
        />
      </Tooltip>
    </Space>
  );
};
