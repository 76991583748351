import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FormCompany } from "../../../containers/forms/form-company/form-company";
import { useGetOneApi } from "rk-react-component";
import { api } from "../../../services/api";
import { MeContext } from "../../../contexts/me-context/me-context";
import { PageFormTabContainer } from "../../../containers/pages/page-form-tab-container/page-form-tab-container";
import { TabAddress } from "../../../containers/tabs/tab-address/tab-address";

export const MyCompanyPage = () => {
  const { role } = useContext(MeContext);
  const navigate = useNavigate();
  const { data, loading } = useGetOneApi<any>({
    url: "/companies",
    id: role?.allow_model_id,
    api,
  });
  useEffect(() => {
    if (role.role !== "COMPANY_MANAGER" && role.role !== "OSC_MANAGER") {
      navigate("/dashboard");
      throw new Error("You are not allowed to access this page");
    }
  }, []);
  return (
    <PageFormTabContainer
      title={"Usuário"}
      items={[
        {
          disabled: false,
          key: "tab_company",
          label: "Info Geral",
          loading: loading,
          FormComponent: (
            <FormCompany
              type={data?.type}
              initialValues={data}
              onFinish={(dataRequest) =>
                navigate("/companies/" + dataRequest?.id)
              }
            />
          ),
        },
        {
          disabled: !data?.id,
          key: "tab_address",
          label: "Endereço",
          loading: false,
          title: "Novo Endereço",
          FormComponent: (
            <TabAddress modelType={"CompanyModel"} modelId={data?.id} />
          ),
        },
      ]}
    />
  );
};
