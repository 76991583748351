import { Typography } from "antd";
import React from "react";
import { TableContainer } from "../table-container/table-container";
import { UserModel } from "../../../@types/user";
import { useNavigate } from "react-router-dom";
import { SwitchRole } from "../../inputs/switch-role/switch-role";
import { formatDate } from "rk-react-component";

interface TableUserProps {
    dataSource: UserModel[];
    loading: boolean;
    setDataList: any;
    pagination: any;
    loadData: any;
    onGoTo?: (record: any) => void;
}

export const TableVolunteer = ({
    dataSource,
    loading,
    setDataList,
    pagination,
    loadData,
                                   onGoTo,
}: TableUserProps) => {
    const navigate = useNavigate();
    return (
        <TableContainer
            loadData={loadData}
            onGoTo={onGoTo}
            pagination={pagination}
            id={"table_volunteer"}
            loading={loading}
            columns={[
                {
                    key: "user_name",
                    dataIndex: "user_name",
                    title: "Nome",
                    render: (val) => (
                        <Typography.Text strong>{val}</Typography.Text>
                    ),
                },
                {
                    key: "user_email",
                    dataIndex: "user_email",
                    title: "Email",
                    render: (val) => (
                        <Typography.Text strong>{val}</Typography.Text>
                    ),
                },
                {
                    key: "company_name",
                    dataIndex: "company_name",
                    title: "Empresa",
                    render: (val) => (
                        <Typography.Text strong>{val}</Typography.Text>
                    ),
                },
                {
                    key: "active",
                    dataIndex: "active",
                    title: "Ativo",
                    render: (val, record) => (
                        <SwitchRole
                            value={val}
                            roleId={record?.id}
                            onUpdate={() =>
                                setDataList((old) =>
                                    old.map((r) => {
                                        if (r.id === record.id) {
                                            return { ...r, active: !val };
                                        }
                                        return r;
                                    }),
                                )
                            }
                        />
                    ),
                },
                {
                    key: "date",
                    dataIndex: "date",
                    title: "Data da Entrevista",
                    render: (val) => (
                        <Typography.Text strong>
                            {formatDate(val)}
                        </Typography.Text>
                    ),
                },
                {
                    key: "created_at",
                    dataIndex: "created_at",
                    title: "Data de Cadastro",
                    render: (val) => (
                        <Typography.Text strong>
                            {formatDate(val)}
                        </Typography.Text>
                    ),
                },
            ]}
            dataSource={dataSource}
        />
    );
};
