import React, { ReactNode } from "react";
import { LoginForm, ProFormText } from "@ant-design/pro-components";
import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { SigninFastLogin } from "./signin-fast-login";
import { Flex, Image, Typography } from "antd";
import { useNavigate } from "react-router-dom";

interface SigninPageProps {
    onSignin: (values: any) => Promise<boolean | void>;
    loading: boolean;
}

export const SigninPage = ({
    onSignin,
    loading,
}: SigninPageProps): ReactNode => {
    const navigate = useNavigate();
    return (
        <div
            style={{
                backgroundColor: "white",
                height: "calc(100vh)",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <Flex>
                <LoginForm
                    loading={loading}
                    onFinish={onSignin}
                    logo={<Image src={require("../../assets/logo.png")} />}
                    title=""
                    subTitle=""
                >
                    <ProFormText
                        name="email"
                        fieldProps={{
                            size: "large",
                            prefix: <UserOutlined className={"prefixIcon"} />,
                        }}
                        placeholder={"Email"}
                    />
                    <ProFormText.Password
                        name="password"
                        fieldProps={{
                            size: "large",
                            prefix: <LockOutlined className={"prefixIcon"} />,
                        }}
                        placeholder={"Senha"}
                    />
                    <SigninFastLogin />
                    <Flex
                        style={{ marginBottom: 12 }}
                        align={"flex-end"}
                        justify={"end"}
                    >
                        <Typography.Link
                            onClick={() => {
                                navigate("/forgot-password");
                            }}
                        >
                            Esqueceu a senha?
                        </Typography.Link>
                    </Flex>
                </LoginForm>
            </Flex>
        </div>
    );
};
