import * as Sentry from "@sentry/react";

export const formatModel = {
    HeadquarterModel: "Sede",
    RegionModel: "Região",
};

export const formatStatus = (status) => {
    const status_constants = {
        draft: "Rascunho",
        pending: "Pendente",
        ongoing: "Em Andamento",
        waiting_company: "Aguardando Aprovação",
        approved: "Aprovado",
        finished: "Finalizado",
        cancelled: "Cancelado",
    };
    const status_formatted = status_constants?.[status];
    if (!status_formatted) {
        // Manda msg para o sentry
        Sentry.captureMessage(
            `Status não encontrado (method: formatStatus / status: ${status})`,
        );
        return status;
    }
    return status_formatted;
};

export const formatRole = (role) => {
    const role_constants = {
        MASTER: "Master",
        COMPANY_MANAGER: "Empresa",
        OSC_MANAGER: "OSC",
    };
    const role_formatted = role_constants?.[role];
    if (!role_formatted) {
        // Manda msg para o sentry
        Sentry.captureMessage(
            `Status não encontrado (method: formatRole / role: ${role})`,
        );
        return role;
    }
    return role_formatted;
};

export const formatAddress = (address: any) =>
    `${address?.street || "-"}, ${address?.number || ","}  ${
        address?.district || "-"
    } ${address?.city || "-"} / ${address?.state || "-"}`;
