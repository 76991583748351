import React, { useEffect } from "react";
import { useGetAllApi } from "rk-react-component";
import { PageIndexContainer } from "../../../containers/pages/page-index-container/page-index-container";
import { api } from "../../../services/api";
import { TableVolunteer } from "../../../containers/tables/table-volunteer/table-volunteer";
import { FormFilterVolunteer } from "../../../containers/form-filter/form-filter-volunteer/form-filter-volunteer";
import { useNavigate } from "react-router-dom";

export const VolunteerIndexPage = () => {
    const navigate = useNavigate();
    const {
        dataList,
        fetchDataList,
        loading,
        setDataList,
        pagination,
        filter,
    } = useGetAllApi<any, any>({
        url: "/volunteers",
        api,
        initialRequest: false,
    });

    useEffect(() => {
        fetchDataList({ page: 1, per_page: 10, ...filter });
    }, []);

    return (
        <PageIndexContainer
            url={"/volunteers"}
            title={"Voluntários"}
            btnTitle={"Novo Voluntário"}
            showBtnAdd={true}
            FormFilterComponent={
                <FormFilterVolunteer
                    onFinish={(data) => fetchDataList(data)}
                    initialValues={filter}
                />
            }
            TableComponent={() => (
                <TableVolunteer
                    loadData={(data) => fetchDataList({ ...filter, ...data })}
                    onGoTo={(data) => navigate(`/volunteers/${data.id}`)}
                    pagination={pagination}
                    dataSource={dataList}
                    loading={loading}
                    setDataList={setDataList}
                />
            )}
        />
    );
};
