import { Tag } from "antd";
import React from "react";

export const TagConfigOption = ({ configOption }: any) => {
    return (
        <Tag
            color={configOption?.back_color || "#000000"}
            style={{ color: configOption?.front_color || "#ffffff" }}
        >
            {configOption?.name}
        </Tag>
    );
};