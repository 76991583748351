import React from "react";
import { Form, Input } from "antd";
import {
    FormFilterContainer,
    FormFilterItem,
    FormItem,
} from "rk-react-component";
import { SelectStatusOrder } from "../../inputs/select-status-order/select-status-order";
import { SelectConfigOptions } from "../../inputs/select-config-options/select-config-options";

interface FormFilterOrderProps {
    onFinish: (data: any) => void;
    initialValues?: any;
}

export const FormFilterOrder = ({ onFinish, initialValues }: FormFilterOrderProps) => {
    const [form] = Form.useForm();
    return (
        <FormFilterContainer
            formProps={{ onFinish, initialValues: { ...initialValues }}}
            form={form}
            hiddenShowMore={true}
        >
            {() => (
                <>
                    <FormFilterItem name={"title_lk"}>
                        <Input placeholder={"Título"} />
                    </FormFilterItem>
                    <FormFilterItem name={"status"}>
                        <SelectStatusOrder
                            allowClear
                            style={{ width: 150 }}
                            placeholder={"Status"}
                        />
                    </FormFilterItem>
                    <FormItem name={"config_option_skill_id"}>
                        <SelectConfigOptions allowClear type={"skill"}  style={{ width: 150 }} placeholder={'Habilidade'}/>
                    </FormItem>
                    <FormItem name={"config_option_audience_id"}>
                        <SelectConfigOptions allowClear type={"publico"}   style={{ width: 150 }} placeholder={"Público"}/>
                    </FormItem>
                </>
            )}
        </FormFilterContainer>
    );
};
