import { Select, SelectProps } from "antd";
import React from "react";
import { OrderStatusEnum } from "../../../@types/order-status-enum";

export const SelectStatusOrder = ({ ...props }: SelectProps) => {
    return (
        <Select
            options={[
                {
                    id: OrderStatusEnum.DRAFT,
                    value: OrderStatusEnum.DRAFT,
                    label: "Rascunho",
                },
                {
                    id: OrderStatusEnum.PENDING,
                    value: OrderStatusEnum.PENDING,
                    label: "Pendente",
                },
                {
                    id: OrderStatusEnum.WAITING_COMPANY,
                    value: OrderStatusEnum.WAITING_COMPANY,
                    label: "Aguardando Aprovação",
                },
                {
                    id: OrderStatusEnum.APPROVED,
                    value: OrderStatusEnum.APPROVED,
                    label: "Aprovada",
                },
                {
                    id: OrderStatusEnum.FINISHED,
                    value: OrderStatusEnum.FINISHED,
                    label: "Finalizado",
                },
                {
                    id: OrderStatusEnum.CANCELLED,
                    value: OrderStatusEnum.CANCELLED,
                    label: "Cancelado",
                },
            ]}
            {...props}
        />
    );
};
