import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { FormCompany } from "../../../containers/forms/form-company/form-company";
import {
    GetOneApiReturn,
    useGetAllApi,
    useGetOneApi,
} from "rk-react-component";
import { CompanyModel } from "../../../@types/company";
import { api } from "../../../services/api";
import { PageFormTabContainer } from "../../../containers/pages/page-form-tab-container/page-form-tab-container";
import { ModalAddRole } from "../../../containers/modals/modal-add-role/modal-add-role";
import { TabAddress } from "../../../containers/tabs/tab-address/tab-address";
import { TabDocument } from "../../../containers/tabs/tab-document/tab-document";
import { TabPanelOsc } from "../../../containers/tabs/tab-panel-osc/tab-panel-osc";

export const OscFormPage = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const { data, loading }: GetOneApiReturn<CompanyModel> = useGetOneApi({
        url: "/companies",
        id,
        api,
    });
    const { fetchDataList } = useGetAllApi<any, any>({
        fixParams: {
            roles: {
                allow_model_type: "CompanyModel",
                allow_model_id: data?.id,
                role: "OSC_MANAGER",
            },
        },
        url: "/users",
        api,
        initialRequest: false,
    });
    useEffect(() => {
        if (data?.id && data?.type !== "OSC") {
            navigate("/oscs");
        }
        if (data?.id && data?.type === "OSC") {
            fetchDataList({});
        }
    }, [data]);
    return (
        <PageFormTabContainer
            title={"OSC"}
            items={[
                {
                    loading,
                    title: "Dados da OSC",
                    label: "Dados da OSC",
                    key: "1",
                    FormComponent: (
                        <FormCompany
                            type={"OSC"}
                            initialValues={data}
                            onFinish={(dataRequest) =>
                                navigate("/oscs/" + dataRequest?.id)
                            }
                        />
                    ),
                },
                {
                    title: "Painel",
                    label: "Painel",
                    loading,
                    key: "panel",
                    disabled: !data?.id,
                    hiddenCard: true,
                    FormComponent: <TabPanelOsc companyId={+data?.id} />,
                },
                {
                    disabled: !id,
                    title: "Responsáveis",
                    label: "Responsáveis",
                    key: "2",
                    FormComponent: (
                        <ModalAddRole
                            fixParams={{
                                role: "OSC_MANAGER",
                                allow_model_id: +id,
                                allow_model_type: "CompanyModel",
                            }}
                        />
                    ),
                },
                {
                    disabled: !id,
                    title: "Endereço",
                    label: "Endereço",
                    key: "3",
                    FormComponent: (
                        <TabAddress
                            modelType={"CompanyModel"}
                            modelId={data?.id}
                        />
                    ),
                },
                {
                    disabled: !id,
                    title: "Documentos",
                    label: "Documentos",
                    key: "tab_document",
                    FormComponent: (
                        <TabDocument
                            modelType={"CompanyModel"}
                            modelId={data?.id}
                            type={"documentos-da-osc"}
                        />
                    ),
                },
            ]}
        />
    );
};
