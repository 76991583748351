import { Button, Divider, Space, Tooltip } from "antd";
import React from "react";
import { MdApproval, MdCancel, MdPublish } from "react-icons/md";
import { EditIcon } from "../../main/icons-list";
import { OrderModel } from "../../@types/order";
import { OrderStatusEnum } from "../../@types/order-status-enum";
import { api } from "../../services/api";
import { useNavigate } from "react-router-dom";

export const OrderStatusAction = ({
    order,
    setLoading,
    setData,
}: {
    order: OrderModel;
    setLoading: (value: boolean) => void;
    setData: (value: any) => void;
}) => {
    const navigate = useNavigate();
    const onChangeStatus = (status: OrderStatusEnum) => {
        setLoading(true);
        api.put(`/orders/${order.id}/${status}`)
            .then(({ data }) => {
                setData(data.data);
            })
            .catch(() => {})
            .finally(() => {
                setLoading(false);
            });
    };

    if (!order?.status) return null;

    return (
        <Space split={<Divider type={"vertical"} />} size={0}>
            <Button
                size={"small"}
                type={"text"}
                onClick={() => onChangeStatus(OrderStatusEnum.APPROVED)}
                disabled={OrderStatusEnum.WAITING_COMPANY !== order.status}
            >
                <Tooltip title={"Aprovar"}>
                    <MdApproval size={20} />
                </Tooltip>
            </Button>
            <Button
                size={"small"}
                type={"text"}
                onClick={() => onChangeStatus(OrderStatusEnum.CANCELLED)}
                disabled={OrderStatusEnum.WAITING_COMPANY !== order.status}
            >
                <Tooltip title={"Recusar"}>
                    <MdCancel size={20} />
                </Tooltip>
            </Button>
            <Button
                size={"small"}
                type={"text"}
                disabled={
                    ![
                        OrderStatusEnum.DRAFT,
                        OrderStatusEnum.CANCELLED,
                    ].includes(order.status)
                }
                onClick={() => onChangeStatus(OrderStatusEnum.PENDING)}
            >
                <Tooltip title={"Publicar"}>
                    <MdPublish size={20} />
                </Tooltip>
            </Button>
            <Button
                size={"small"}
                type={"text"}
                onClick={() => navigate(`/orders/${order.id}`)}
                disabled={
                    ![
                        OrderStatusEnum.DRAFT,
                        OrderStatusEnum.PENDING,
                        OrderStatusEnum.CANCELLED,
                    ].includes(order?.status)
                }
            >
                <Tooltip title={"Editar"}>
                    <EditIcon size={20} />
                </Tooltip>
            </Button>
        </Space>
    );
};