import React from "react";
import {
    FormContainer,
    FormItem,
    InputCpf,
    InputPhone,
    SelectAction,
    useFormApi,
} from "rk-react-component";
import { Form, Input, Segmented, Switch } from "antd";
import { RoleModel } from "../../../@types/role";
import { api } from "../../../services/api";
import { DatePickerMoment } from "../../../components/date-picke-moment/date-picker-moment";

interface FormVolunteerProps {
    onFinish: (data: RoleModel) => void;
    initialValues?: RoleModel | null;
    companyId: number;
}

export const FormVolunteer = ({
                                 onFinish,
                                 initialValues,
    companyId
                             }: FormVolunteerProps) => {
    const [form] = Form.useForm();
    const type_form = Form.useWatch('type_form', form)
    const {save: saveUser, loading: loadingUser} = useFormApi<any, any>({
        url: '/users',
        id: undefined,
        api
    })
    const {save, loading} = useFormApi<any, any>({
        url: `/roles`,
        id: initialValues?.id,
        fixParams: {
            role: 'VOLUNTEER',
            allow_model_type: 'CompanyModel',
            allow_model_id: companyId
        },
        api,
    });
    console.log(initialValues, 'init')
    return (
        <FormContainer
            labelCol={{ span: 24 }}
            dateCast={['date']}
            name={"form_role_user"}
            initialValues={{type_form: 'select', ...initialValues}}
            form={form}
            onFinish={(data) => {
                if (type_form === 'new') {
                    saveUser(data, (response) => {
                        save({user_id: response?.id, active: data?.active}, () => {
                            onFinish(response);
                        }, () => {

                        })
                    }, () => {

                    })
                } else if (type_form === 'select') {
                    save(data, (response) => {
                        onFinish(response);
                    }, (e) => console.warn(e));
                }
            }}
            loading={loading || loadingUser}
        >
            {!initialValues?.id ? (
                <FormItem label={'Como deseja adicionar?'} name={'type_form'}>
                    <Segmented
                        options={[
                            {label: 'Selecionar Usuário', value: 'select', icon: undefined},
                            {label: 'Novo Usuário', value: 'new', icon: undefined},
                        ]}
                    />
                </FormItem>
            ) : null}
            {type_form === 'select' || initialValues?.id ? (
                <>
                <FormItem
                    label={"Usuário"}
                    name={"user_id"}
                >
                    <SelectAction api={api} url={"/users"} fieldSearch={'name_lk'} disabled={!!initialValues?.id}/>
                </FormItem>
            </>
            ) : (
                <>
                    <FormItem label={"Nome"} name={"name"} rules={[{required: true, message: 'O Nome é obrigatório'}]}>
                        <Input placeholder={"Nome"}/>
                    </FormItem>
                    <FormItem label={"Email"} name={"email"}
                              rules={[{required: true, message: 'O Email é obrigatório'}]}>
                        <Input placeholder={"Email"}/>
                    </FormItem>
                    <FormItem label={"CPF"} name={"document_cpf"}
                              rules={[{required: true, message: 'O CPF é obrigatório'}]}>
                        <InputCpf placeholder={"CPF"}/>
                    </FormItem>
                    <FormItem label={"Celular"} name={"mobile"}
                              rules={[{required: true, message: 'O Celular é obrigatório'}]}>
                        <InputPhone placeholder={"Celular"}/>
                    </FormItem>
                    <FormItem label={"Telefone"} name={"phone"}
                              rules={[{required: true, message: 'O Telefone é obrigatório'}]}>
                        <InputPhone placeholder={"Telefone"}/>
                    </FormItem>
                    <FormItem label={"Senha"} name={"password"}
                              rules={[{required: true, message: 'A Senha é obrigatória'}]}>
                        <Input.Password placeholder={"Senha"}/>
                    </FormItem>
                </>
            )}
            <FormItem name={"active"} label={"Ativo"} valuePropName={"checked"}>
                <Switch/>
            </FormItem>
            <FormItem name={'date'} label={'Data da Entrevista'}>
                <DatePickerMoment format={'DD/MM/YYYY'}/>
            </FormItem>
        </FormContainer>
    );
};
