import React from "react";
import {
  FormContainer,
  FormItem,
  FormItemMorph,
  FormItemUpload,
  getBase64,
  MediaModel,
  useFormApi,
} from "rk-react-component";
import { Form, Input } from "antd";
import { api } from "../../../services/api";

interface FormMediaProps {
  onFinish: (data: MediaModel) => void;
  initialValues?: MediaModel | null;
  withModelType?: boolean;
  fixParams?: any;
  onBack?: () => void;
}

export const FormMedia = ({
  onFinish,
  initialValues,
  withModelType,
  fixParams,
  onBack,
}: FormMediaProps) => {
  const [form] = Form.useForm();
  const { save, loading } = useFormApi<any, any>({
    url: "/medias",
    id: initialValues?.id,
    api,
  });
  return (
    <FormContainer
      labelCol={{ span: 24 }}
      name={"form_media"}
      initialValues={initialValues}
      form={form}
      onBack={onBack}
      onFinish={async (data) => {
        getBase64(data.media_upload.file.originFileObj, async (url) => {
          data.media_upload = url;
          save(
            { ...data, ...fixParams },
            (response) => {
              onFinish(response);
            },
            (e) => console.warn(e),
          );
        });
      }}
      loading={loading}
    >
      <FormItemUpload />
      <FormItem label={"Nome"} name={"name"}>
        <Input />
      </FormItem>
      <FormItem label={"Nome da Coleção"} name={"collection_name"}>
        <Input />
      </FormItem>
      {withModelType && (
        <FormItemMorph
          api={api}
          fieldNameType={"model_type"}
          fieldNameId={"model_id"}
          labelType={"Tipo de Modelo"}
          labelId={"Modelo"}
          options={["CabinetOptionsMorph", "PartyOptionsMorph"]}
        />
      )}
    </FormContainer>
  );
};
