import { Select, SelectProps } from "antd";
import React from "react";

export const SelectBoolean = ({ ...props }: SelectProps) => {
  return (
    <Select
      allowClear
      options={[
        { label: "Sim", value: "1" },
        { label: "Não", value: "0" },
      ]}
      {...props}
    />
  );
};