import React from "react";
import {Form, Input} from "antd";
import {FormFilterContainer, FormFilterItem} from "rk-react-component";

interface FormFilterPartyProps {
    onFinish: (data: any) => void;
}

export const FormFilterRoleUser = ({onFinish}: FormFilterPartyProps) => {
    const [form] = Form.useForm();
    return (
        <FormFilterContainer formProps={{onFinish}} form={form} hiddenShowMore={true}>
            {() => (
                <>
                    <FormFilterItem name={["user", "name_lk"]}>
                        <Input placeholder={"Nome"}/>
                    </FormFilterItem>
                    <FormFilterItem name={["user", "email_lk"]}>
                        <Input placeholder={"Email"}/>
                    </FormFilterItem>
                </>
            )}
        </FormFilterContainer>
    );
};
