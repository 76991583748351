import React, { useState } from "react";
import { Button, Flex, Input, Modal, Rate } from "antd";

export const ModalRating = ({ onFinish }) => {
    const [showModal, setShowModal] = useState(false);
    const [rating, setRating] = useState(0);
    const [comment, setComment] = useState("");
    return (
        <>
            <Button
                type={"primary"}
                size={"small"}
                onClick={() => {
                    setShowModal(true);
                }}
            >
                Avaliar
            </Button>
            <Modal
                title={"Avaliar Atividade"}
                onCancel={() => setShowModal(false)}
                open={showModal}
                footer={false}
            >
                <Flex
                    gap={12}
                    vertical
                    align={"center"}
                    style={{ marginTop: 12 }}
                >
                    <Rate
                        style={{ fontSize: 40 }}
                        onChange={(val) => setRating(val)}
                        value={rating}
                    />
                    <Input.TextArea
                        value={comment}
                        onChange={({ target }) => setComment(target.value)}
                    />
                    <Button
                        type={"primary"}
                        onClick={() => {
                            setShowModal(false);
                            onFinish({ rating, comment });
                        }}
                    >
                        Avaliar
                    </Button>
                </Flex>
            </Modal>
        </>
    );
};
