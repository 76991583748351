import {
    Link,
    Route,
    Routes,
    useLocation,
    useNavigate,
} from "react-router-dom";
import React, { useContext, useEffect } from "react";
import { ForgotPasswordPage, Layout } from "rk-react-component";
import { configProject } from "../../../config-project";
import { MenuExtraRender } from "../menu-extra-render/menu-extra-render";
import { useRouteRole } from "../../../hooks/use-route-role";
import { Image, notification } from "antd";
import { MeContext } from "../../../contexts/me-context/me-context";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import { FooterLayout } from "../../footer-layout";
import logoMobile from "../../../assets/logo-icon.png";
import logoImg from "../../../assets/logo-icon.png";
import logoFull from "../../../assets/logo.png";
import { api } from "../../../services/api";
import { SigninFactory } from "../../../pages/signin/signin-factory";
import { ResetPasswordFactory } from "../../../pages/reset-password/reset-password-factory";

const token = {
    sider: {
        colorMenuBackground: configProject.colorMenuBackground,
        colorTextMenu: configProject.colorTextMenu,
        colorTextMenuSelected: configProject.colorTextMenuSelected,
        colorTextMenuItemHover: configProject.colorTextMenuSelected,
    },
    colorPrimary: configProject.colorPrimary,
    colorTitle: "white",
    header: {
        colorBgHeader: configProject.colorBgHeader,
    },
};

export const MainLayout = () => {
    const navigate = useNavigate();
    const { me } = useContext(MeContext);
    const location = useLocation();
    const { width } = useWindowDimensions();
    const style = width < 500 ? { marginLeft: -16, marginRight: -16 } : {};
    const { routes } = useRouteRole();

    const [isMounted, setIsMounted] = React.useState(false);

    useEffect(() => {
        setIsMounted(true);
    }, []);

    useEffect(() => {
        if (isMounted) {
            if (
                !me?.id &&
                location.pathname !== "/signin" &&
                location.pathname !== "/forgot-password" &&
                !location.pathname.includes("/signup") &&
                !location.pathname.includes("/reset-password")
            ) {
                navigate("/signin");
            }
            if (
                me?.id &&
                (location.pathname === "/signin" ||
                    location.pathname.includes("/signup") ||
                    location.pathname === "/forgot-password" ||
                    location.pathname === "/reset-password")
            ) {
                navigate("/");
                notification.info({ message: "Você já está logado!" });
            }
        }
        // eslint-disable-next-line
    }, [me, isMounted]);

    if (!me?.id) {
        return (
            <Routes>
                <Route path={"/signin"} element={<SigninFactory />} />
                <Route
                    path={"/forgot-password"}
                    element={
                        <ForgotPasswordPage
                            api={api}
                            logo={logoFull}
                            withNotification={true}
                            logoProps={{ style: { width: 280 } }}
                            goLogin={() => navigate("/signin")}
                        />
                    }
                />
                <Route
                    path={"/reset-password/:code"}
                    element={
                        <ResetPasswordFactory/>
                    }
                />
            </Routes>
        );
    }
    return (
        <Layout
            menuHeaderRender={(logo, _title, configuration) => {
                const collapsed =
                    configuration?.collapsed === undefined
                        ? true
                        : configuration?.collapsed;
                if (
                    configuration?.collapsed === undefined &&
                    configuration?.isMobile === undefined
                ) {
                    return (
                        <Image
                            style={{ marginBottom: 24 }}
                            height={30}
                            src={logoMobile} // Mobile
                            preview={false}
                        />
                    );
                }
                if (collapsed && !configuration?.isMobile) {
                    return (
                        <Image
                            style={{ maxHeight: 30 }}
                            src={logoImg} // Collapsed
                            // src={require("../assets/logo-white-vertical.png")} // Collapsed
                            preview={false}
                        />
                    );
                }
                return collapsed ? (
                    logo
                ) : (
                    <div
                        style={{
                            width: "100%",
                            justifyContent: "center",
                            alignItems: "center",
                            display: "flex",
                            marginTop: configuration?.isMobile ? 8 : 0,
                            marginLeft: configuration?.isMobile ? 12 : 0,
                            marginBottom: configuration?.isMobile ? 12 : 0,
                        }}
                    >
                        <Image
                            style={{ maxHeight: 36 }}
                            src={require("../../../assets/img.png")} // Open sidebar
                            preview={false}
                        />
                    </div>
                );
            }}
            menuExtraRender={MenuExtraRender}
            title={""}
            token={token}
            locationRouter={location}
            logo={logoImg}
            LinkComponent={Link}
            routes={routes?.location}
            menuFooterRender={FooterLayout}
            style={{ height: "100vh" }}
        >
            <div style={style}>{routes.routes}</div>
        </Layout>
    );
};
