import { Typography } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import { TableContainer } from "../table-container/table-container";
import { RoleModel } from "../../../@types/role";
import { formatDate } from "rk-react-component";

interface TableRoleUserProps {
    dataSource: RoleModel[];
    loading: boolean;
    role: string;
    onDelete: any;
    loadData: any;
    pagination: any;
    btnTitle?: string;
}

export const TableRoleUser = ({
                                  dataSource,
                                  loading,
                                  role,
                                  onDelete,
                                  loadData,
                                  pagination, btnTitle = 'Novo Responsável'
                              }: TableRoleUserProps) => {
    return (
        <TableContainer
            key={"id"}
            id={"table_role_user"}
            loading={loading}
            onDelete={onDelete}
            loadData={loadData}
            pagination={pagination}
            columns={[
                {
                    fixed: true,
                    key: "id",
                    dataIndex: "id",
                    title: "Cód.",
                    render: (val) => <Link to={`/roles/${role}/${val}`}>{val}</Link>,
                },
                {
                    fixed: true,
                    key: "user.name",
                    dataIndex: "user.name",
                    title: "Nome",
                    render: (val, record) => (
                        <Typography.Text strong>{record?.user?.name}</Typography.Text>
                    ),
                },
                {
                    key: "user.email",
                    dataIndex: "user.email",
                    title: "Email",
                    render: (val, record) => (
                        <Typography.Text>{record?.user?.email}</Typography.Text>
                    ),
                },
                {
                    key: "date",
                    dataIndex: "date",
                    title: "Data da Entrevista",
                    render: (val, record) => formatDate(record?.date),
                },
            ]}
            dataSource={dataSource}
        />
    );
};
