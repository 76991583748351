import { api } from "../../../services/api";
import { SelectAction } from "rk-react-component";
import React from "react";
import { SelectProps } from "antd";

interface SelectConfigOptionsProps extends SelectProps {
    defaultOptions?: any;
    type: string;
}

export const SelectConfigOptions = ({defaultOptions, type, ...props}: SelectConfigOptionsProps) => {
	return (
		<SelectAction
			defaultOptions={defaultOptions ? [defaultOptions] : []}
			api={api}
			url={"/config-options"}
			fixParams={{
				type,
				parent: { parent_id_is_null: true },
			}}
			{...props}
		/>
	)
}