import { Space } from "antd";
import React from "react";
import { useFetchDashboard } from "../../../pages/dashboard/use-fetch-dashboard";
import { CardFormPeriod } from "../../../pages/dashboard/card-form-period";
import { WidgetComponent } from "../../../pages/dashboard/widget-component";
import { PieChartGroup } from "../../../pages/dashboard/pie-chart-group";
import { EvolutionChartGroup } from "../../../pages/dashboard/evolution-chart-group";

interface TabPanelCompanyProps {
    companyId: number;
}

export const TabPanelOsc = ({companyId}: TabPanelCompanyProps) => {
    const { dashboard, fetchDashboard, loading } = useFetchDashboard({
        type: "master-company",
      fixParams: {
        company_id: companyId
      }
    });
    return (
        <Space direction={"vertical"} style={{ width: "100%" }}>
            <CardFormPeriod onFinish={fetchDashboard} loading={loading} />
            <WidgetComponent dashboardData={dashboard} loading={loading} />
            <PieChartGroup dashboardData={dashboard} loading={loading} />
            <EvolutionChartGroup dashboardData={dashboard} loading={loading} />
        </Space>
    );
};
