import React, { useContext } from "react";
import { useGetOneApi } from "rk-react-component";
import { MeContext } from "../../../contexts/me-context/me-context";
import { api } from "../../../services/api";
import { PageFormTabContainer } from "../../../containers/pages/page-form-tab-container/page-form-tab-container";
import { FormUser } from "../../../containers/forms/form-user/form-user";
import { FormPassword } from "../../../containers/forms/form-password/form-password";
import { TabAddress } from "../../../containers/tabs/tab-address/tab-address";

export const MyProfileFormPage = () => {
  const { setMe, me } = useContext(MeContext);
  const { data: user, loading: loadingUser } = useGetOneApi<any>({
    url: "/users",
    id: me?.id,
    api,
  });

  return (
    <PageFormTabContainer
      title={"Meu Perfil"}
      items={[
        {
          disabled: false,
          key: "tab_user",
          label: "Info Geral",
          loading: loadingUser,
          title: "Meu Perfil",
          FormComponent: (
            <FormUser
              initialValues={user}
              onFinish={(dataApi) => {
                setMe(dataApi);
                localStorage.setItem("user", JSON.stringify(dataApi));
              }}
            />
          ),
        },
        {
          disabled: !user?.id,
          key: "tab_address",
          label: "Endereço",
          title: "Endereço",
          FormComponent: (
            <TabAddress modelType={"UsersModel"} modelId={user?.id} />
          ),
        },
        {
          disabled: !user?.id,
          key: "tab_password",
          label: "Senha",
          title: "Trocar Senha",
          FormComponent: <FormPassword onFinish={() => {}} userId={user?.id} />,
        },
      ]}
    />
  );
};
