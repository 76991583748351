import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { FormCompany } from "../../../containers/forms/form-company/form-company";
import {
    GetOneApiReturn,
    useGetAllApi,
    useGetOneApi,
} from "rk-react-component";
import { CompanyModel } from "../../../@types/company";
import { api } from "../../../services/api";
import { PageFormTabContainer } from "../../../containers/pages/page-form-tab-container/page-form-tab-container";
import { ModalAddRole } from "../../../containers/modals/modal-add-role/modal-add-role";
import { TabAddress } from "../../../containers/tabs/tab-address/tab-address";
import { TabDocument } from "../../../containers/tabs/tab-document/tab-document";
import { TabPanelCompany } from "../../../containers/tabs/tab-panel-company/tab-panel-company";

export const CompanyFormPage = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const { data, loading }: GetOneApiReturn<CompanyModel> = useGetOneApi({
        url: "/companies",
        id,
        api,
    });
    const { fetchDataList: fetchDataResponsible } = useGetAllApi({
        fixParams: {
            roles: {
                allow_model_type: "CompanyModel",
                allow_model_id: data?.id,
                role: "COMPANY_MANAGER",
            },
        },
        url: "/users",
        api,
        initialRequest: false,
    });
    const { fetchDataList: fetchDataVolunteer } = useGetAllApi({
        fixParams: {
            roles: {
                allow_model_type: "CompanyModel",
                allow_model_id: data?.id,
                role: "VOLUNTEER",
            },
        },
        url: "/users",
        api,
        initialRequest: false,
    });

    useEffect(() => {
        if (data?.id && data?.type !== "COMPANY") {
            navigate("/companies");
        }
        if (data?.id && data?.type === "COMPANY") {
            fetchDataResponsible({});
            fetchDataVolunteer({});
        }
    }, [data]);

    return (
        <PageFormTabContainer
            title={"Empresa"}
            items={[
                {
                    title: "Dados da Empresa",
                    label: "Dados da Empresa",
                    loading,
                    key: "1",
                    FormComponent: (
                        <FormCompany
                            type={"COMPANY"}
                            fixParams={{ type: "COMPANY" }}
                            initialValues={data}
                            onFinish={(dataRequest) =>
                                navigate("/companies/" + dataRequest?.id)
                            }
                        />
                    ),
                },
                {
                    title: "Painel",
                    label: "Painel",
                    loading,
                    key: "panel",
                    disabled: !data?.id,
                    hiddenCard: true,
                    FormComponent: <TabPanelCompany companyId={+data?.id} />,
                },
                {
                    disabled: !id,
                    title: "Responsáveis",
                    label: "Responsáveis",
                    key: "2",
                    FormComponent: (
                        <ModalAddRole
                            fixParams={{
                                role: "COMPANY_MANAGER",
                                allow_model_id: +id,
                                allow_model_type: "CompanyModel",
                            }}
                        />
                    ),
                },
                {
                    disabled: !id,
                    title: "Voluntários",
                    label: "Voluntários",
                    key: "3",
                    FormComponent: (
                        <ModalAddRole
                            btnTitle={"Novo Voluntário"}
                            fixParams={{
                                role: "VOLUNTEER",
                                allow_model_id: +id,
                                allow_model_type: "CompanyModel",
                            }}
                        />
                    ),
                },
                {
                    disabled: !id,
                    title: "Endereço",
                    label: "Endereço",
                    key: "4",
                    FormComponent: (
                        <TabAddress
                            modelType={"CompanyModel"}
                            modelId={data?.id}
                        />
                    ),
                },
                {
                    disabled: !id,
                    title: "Documentos",
                    label: "Documentos",
                    key: "5",
                    FormComponent: (
                        <TabDocument
                            modelType={"CompanyModel"}
                            modelId={data?.id}
                            type={"documentos-da-empresa"}
                        />
                    ),
                },
            ]}
        />
    );
};
