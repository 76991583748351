import React, { useContext, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useGetOneApi } from "rk-react-component";
import { PageFormContainer } from "../../../containers/pages/page-form-container/page-form-container";
import { api } from "../../../services/api";
import { FormOrder } from "../../../containers/forms/form-order/form-order";
import { MeContext } from "../../../contexts/me-context/me-context";

const urlPrefix = "/orders";

export const OrderFormPage = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { role } = useContext(MeContext);
  const { data, loading } = useGetOneApi<any>({ url: urlPrefix, id, api });
  const fixParams = useMemo(() => {
    if (role.role === "OSC_MANAGER") {
      return {
        requester_company_id: role?.allow_model_id,
      };
    }
    return {};
  }, [role]);
  return (
    <PageFormContainer
      title={"Atividade"}
      FormComponent={
        <FormOrder
          dateCast={["date"]}
          fixParams={fixParams}
          initialValues={data}
          onFinish={(dataApi) => navigate(`${urlPrefix}/show/${dataApi.id}`)}
        />
      }
      loading={loading}
    />
  );
};
