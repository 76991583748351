import React, { useEffect } from "react";
import { Charts } from "rk-common";
import { api } from "../../services/api";

// TODO Migrar os types para um ENUM
interface UseFetchDashboardProps {
    type: "osc" | "company" | "master" | "master-company" | "company-volunteer";
    fixParams?: any;
}
export const useFetchDashboard = ({type, fixParams = {}}: UseFetchDashboardProps) => {
    const [dashboard, setDashboard] = React.useState<any>({});
    const [loading, setLoading] = React.useState<boolean>(false);
    useEffect(() => {
        fetchDashboard({ range: { key: Charts.Helpers.ChartRanges.Range7 } });
    }, []);

    const fetchDashboard = (data: any = {}) => {
        setLoading(true)
        api.get(`/dashboard/${type}`, {
            params: { ...data, ...fixParams}
        })
            .then(({ data }) => {
                setDashboard(data?.data);
            })
            .finally(() => {
                setLoading(false);
            });
    };
    return {dashboard, loading, fetchDashboard}
}