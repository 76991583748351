import {
  Avatar,
  Button,
  Divider,
  Modal,
  Space,
  Tag,
  Tooltip,
  TreeSelect,
  Typography,
} from "antd";
import { configProject } from "../../../config-project";
import React, { useContext, useState } from "react";
import { MeContext } from "../../../contexts/me-context/me-context";
import { useNavigate } from "react-router-dom";
import { FaSearch } from "react-icons/fa";
import {
  CompanyIcon,
  LogoutIcon,
  ProfileIcon,
  SearchIcon,
} from "../../icons-list";
import { formatRole } from "../../../services/format";
import { formatAvatarSlug } from "rk-react-component";

const ICON_SIZE = 20;

export const MenuExtraRender = ({collapsed, isMobile}) => {
  const style = {}
  const {role, me, setRole, setMe} = useContext(MeContext);
  const [showModal, setShowModal] = useState(false)
  const navigate = useNavigate();
  if (collapsed) {
    return null
  }
  if(isMobile) {
    style['marginLeft'] = 16
    style['marginRight'] = 16
  }

  return (
    <>
      <Space direction={"vertical"} style={{ marginTop: 12, ...style }}>
        <Space direction={"horizontal"}>
          <Avatar size={"large"} style={{ marginRight: 8 }}>
            {formatAvatarSlug(me?.name)}
          </Avatar>
          <div>
            <Typography.Paragraph strong style={{ color: "white", margin: 0 }}>
              {me?.name}
            </Typography.Paragraph>
            <Tag>{formatRole(role?.role)}</Tag>
          </div>
        </Space>
        <Space
          direction={"horizontal"}
          size={0}
          split={<Divider type={"vertical"} />}
        >
          <Tooltip title={"Meu Perfil"}>
            <Button
              onClick={() => navigate("/my-profile")}
              type={"link"}
              icon={
                <ProfileIcon
                  size={ICON_SIZE}
                  color={configProject.colorTextMenuSelected}
                />
              }
            />
          </Tooltip>
          {(role?.role === "COMPANY_MANAGER" ||
            role?.role === "OSC_MANAGER") && (
            <Tooltip
              title={
                role?.role === "COMPANY_MANAGER" ? "Minha Empresa" : "Minha OSC"
              }
            >
              <Button
                onClick={() => navigate("/my-company")}
                type={"link"}
                icon={
                  <CompanyIcon
                    size={ICON_SIZE}
                    color={configProject.colorTextMenuSelected}
                  />
                }
              />
            </Tooltip>
          )}
          <Tooltip title={"Busca Global"}>
            <Button
              type={"link"}
              icon={
                <SearchIcon
                  size={ICON_SIZE}
                  color={configProject.colorTextMenuSelected}
                />
              }
            />
          </Tooltip>
          <Tooltip title={"Sair"}>
            <Button
              type={"link"}
              onClick={() => {
                setMe(null);
                setRole(null);
                localStorage.clear();
                navigate("/signin");
              }}
              icon={
                <LogoutIcon
                  size={ICON_SIZE}
                  style={{ cursor: "pointer" }}
                  color={configProject.colorTextMenuSelected}
                />
              }
            />
          </Tooltip>
        </Space>
        <Divider style={{ marginTop: 12, marginBottom: 12 }} />
      </Space>
      <Modal
        open={showModal}
        footer={null}
        onCancel={() => setShowModal(false)}
      >
        <Space>
          <FaSearch color={configProject.colorPrimary} size={40} />
          <TreeSelect />
        </Space>
      </Modal>
    </>
  );
}
