import React, { ReactNode, useEffect } from "react";
import { useGetAllApi } from "rk-react-component";
import { TableCompany } from "../../../containers/tables/table-company/table-company";
import { PageIndexContainer } from "../../../containers/pages/page-index-container/page-index-container";
import { FormFilterCompany } from "../../../containers/form-filter/form-filter-company/form-filter-company";
import { api } from "../../../services/api";
import { useNavigate } from "react-router-dom";

const URL_FRONT_PREFIX = "/companies";

export const CompanyIndexPage = (): ReactNode => {
    const navigate = useNavigate();
    const { dataList, fetchDataList, loading, pagination, filter } = useGetAllApi<
        any,
        any
    >({
        fixParams: { type: "COMPANY" },
        url: "/companies",
        api,
        initialRequest: false,
        filterKey: "companyIndexPage",
    });

    useEffect(() => {
        fetchDataList({ page: 1, per_page: 10, ...filter });
    }, []);

    return (
        <PageIndexContainer
            url={URL_FRONT_PREFIX}
            title={"Empresa"}
            btnTitle={"Nova Empresa"}
            FormFilterComponent={
                <FormFilterCompany onFinish={(data) => fetchDataList(data)} initialValues={filter}/>
            }
            TableComponent={() => (
                <TableCompany
                    onEdit={(record) => {
                        navigate(`${URL_FRONT_PREFIX}/${record.id}`);
                    }}
                    dataSource={dataList}
                    loading={loading}
                    pagination={pagination}
                    loadData={(data) => fetchDataList({ ...filter, ...data})}
                />
            )}
        />
    );
};
