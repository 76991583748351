import React, { useContext } from "react";
import { SigninPage } from "./signin-page";
import { MeContext } from "../../contexts/me-context/me-context";
import { api } from "../../services/api";
import { notification } from "antd";

export const SigninFactory = () => {
    const { setMe, setRole } = useContext(MeContext);
    const [loading, setLoading] = React.useState(false);
    return (
        <SigninPage
            loading={loading}
            onSignin={async ({ email = "", password = "" }) => {
                setLoading(true);
                api.post("/sign-in", {
                    email: email.trim(),
                    password: password.trim(),
                })
                    .then(({ data: dataUser }) => {
                        const activeRole = dataUser?.active_role;
                        if (activeRole?.role === "VOLUNTEER") {
                            notification.error({
                                message:
                                    "Voluntário não tem permissão para acessar o sistema",
                            });
                            return;
                        }
                        // eslint-disable-next-line no-undef
                        localStorage.setItem("user", JSON.stringify(dataUser));
                        setMe(dataUser);
                        // eslint-disable-next-line no-undef
                        localStorage.setItem(
                            "active_role",
                            JSON.stringify(activeRole),
                        );
                        setRole(activeRole);
                    })
                    .catch(() => {
                        notification.error({ message: "Erro ao fazer login" });
                    })
                    .finally(() => setLoading(false));
            }}
        />
    );
};
