import React from "react";
import { Route } from "react-router-dom";
import { FiFilm } from "react-icons/fi";
import { CompanyIndexPage } from "../pages/company/company-index-page/company-index-page";
import { CompanyFormPage } from "../pages/company/company-form-page/company-form-page";
import { UserIndexPage } from "../pages/user/user-index-page/user-index-page";
import { UserFormPage } from "../pages/user/user-form-page/user-form-page";
import { MediaIndexPage } from "../pages/media/media-index-page/media-index-page";
import { MediaFormPage } from "../pages/media/media-form-page/media-form-page";
import {
    CompanyIcon,
    ConfigOptionIcon,
    DashboardIcon,
    ONGIcon,
    OrderIcon,
    ProfileIcon,
    VolunteerIcon,
} from "./icons-list";
import { OrderIndexPage } from "../pages/order/order-index-page/order-index-page";
import { OrderFormPage } from "../pages/order/order-form-page/order-form-page";
import { ConfigOptionIndexPage } from "../pages/config-options/config-options-index/config-option-index-page";
import { MyProfileFormPage } from "../pages/user/my-profile-page/my-profile-page";
import { OscFormPage } from "../pages/osc/osc-form-page/osc-form-page";
import { OscIndexPage } from "../pages/osc/osc-index-page/osc-index-page";
import { OrderViewPage } from "../pages/order/order-view-page/order-view-page";
import { VolunteerFormPage } from "../pages/volunteer/volunteer-form-page/volunteer-form-page";
import { VolunteerIndexPage } from "../pages/volunteer/volunteer-index-page/volunteer-index-page";
import { FaUser } from "react-icons/fa";
import { VolunteerMasterIndexPage } from "../pages/volunteer-master/volunteer-master-index-page/volunteer-master-index-page";
import { DashboardPage } from "../pages/dashboard/dashboard-page";
import { VolunteerMasterFormPage } from "../pages/volunteer-master/volunteer-master-form-page/volunteer-master-form-page";
import { VolunteerViewPage } from "../pages/volunteer/volunteer-view-page/volunteer-view-page";

export const profile = {
    location: {
        path: "/my-profile",
        name: "Meu Perfil",
        icon: <ProfileIcon />,
    },
    routes: (
        <>
            <Route path={"/my-profile"} element={<MyProfileFormPage />} />
        </>
    ),
};
export const dashboardMaster = {
    location: {path: "/", name: "Painel", icon: <DashboardIcon/>},
    routes: <Route path={""} element={<DashboardPage type={'master'}/>}/>,
};
export const dashboardCompany = {
    location: {path: "/", name: "Painel", icon: <DashboardIcon/>},
    routes: <Route path={""} element={<DashboardPage type={'company'}/>}/>,
};
export const dashboardOsc = {
    location: {path: "/", name: "Painel", icon: <DashboardIcon/>},
    routes: <Route path={""} element={<DashboardPage type={'osc'}/>}/>,
};
export const config_option = {
  location: {path: "/config-options", name: "Configurações", icon: <ConfigOptionIcon/>},
  routes: <Route path={"/config-options"} element={<ConfigOptionIndexPage/>}/>,
};
export const volunteer = {
    location: {path: "/volunteers", name: "Voluntários", icon: <VolunteerIcon/>},
    routes: (
        <>
            <Route path={"/volunteers/store"} element={<VolunteerFormPage/>}/>
            <Route path={"/volunteers/:id"} element={<VolunteerViewPage/>}/>
            <Route path={"/volunteers"} element={<VolunteerIndexPage/>}/>
        </>
    ),
};

export const volunteer_master = {
  location: {path: "/volunteers", name: "Voluntários", icon: <VolunteerIcon/>},
  routes: (
      <>
          <Route path={"/volunteers/store"} element={<VolunteerMasterFormPage/>}/>
          <Route path={"/volunteers/:id"} element={<VolunteerViewPage/>}/>
          <Route path={"/volunteers"} element={<VolunteerMasterIndexPage/>}/>
      </>
  ),
};
export const company = {
  location: {path: "/companies", name: "Empresas", icon: <CompanyIcon/>},
  routes: (
    <>
      <Route path={"/companies/store"} element={<CompanyFormPage/>}/>
      <Route path={"/companies/:id"} element={<CompanyFormPage/>}/>
      <Route path={"/companies"} element={<CompanyIndexPage/>}/>
    </>
  ),
};
export const ong = {
  location: {path: "/oscs", name: "OSCs", icon: <ONGIcon/>},
  routes: (
    <>
      <Route path={"/oscs/store"} element={<OscFormPage/>}/>
      <Route path={"/oscs/:id"} element={<OscFormPage/>}/>
      <Route path={"/oscs"} element={<OscIndexPage />}/>
    </>
  ),
};
export const order = {
  location: {path: "/orders", name: "Atividades", icon: <OrderIcon/>},
  routes: (
    <>
      <Route path={"/orders/store"} element={<OrderFormPage/>}/>
      <Route path={"/orders/show/:id"} element={<OrderViewPage/>}/>
      <Route path={"/orders/:id"} element={<OrderFormPage/>}/>
      <Route path={"/orders"} element={<OrderIndexPage/>}/>
    </>
  ),
};
export const report = {
  location: {path: "/reports", name: "Relatórios", icon: <FiFilm/>},
  routes: null,
};
export const user = {
  location: {path: "/users", name: "Usuários", icon: <FaUser/>},
  routes: (
    <>
      <Route path={"/users/store"} element={<UserFormPage/>}/>
      <Route path={"/users/:id"} element={<UserFormPage/>}/>
      <Route path={"/users"} element={<UserIndexPage/>}/>
    </>
  ),
}
export const media = {
  location: {path: "/medias", name: "Mídias", icon: <FiFilm/>},
  routes: (
    <>
      <Route path={"/medias/store"} element={<MediaFormPage/>}/>
      <Route path={"/medias/:id"} element={<MediaFormPage/>}/>
      <Route path={"/medias"} element={<MediaIndexPage/>}/>
    </>
  ),
}
