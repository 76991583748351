import React from "react";
import {
  PageHeader as PH,
  PageHeaderProps as PhProps,
} from "@ant-design/pro-components";

interface PageHeaderProps extends PhProps {}

export const PageHeader = ({ ...props }: PageHeaderProps) => {
  return <PH {...props} />;
};
