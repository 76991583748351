import React from "react";
import { ColumnBoolean } from "./column-boolean/column-boolean";
import { Flex, Tooltip, Typography } from "antd";
import { FaInfoCircle } from "react-icons/fa";
import { formatDate } from "rk-react-component";

interface ColumnBooleanProps {
    title: string;
    field: string;
    tooltip?: string;
}

export const helpColumnCreatedAt = () => {
    return {
        dataIndex: 'created_at',
        key: 'created_at',
        title: 'Criado em',
        render: (val: any) => formatDate(val),
    };

}

export const helpColumnBoolean = ({
  title,
  field,
  tooltip,
}: ColumnBooleanProps) => {
  return {
    dataIndex: field,
    key: field,
    title: (
      <Flex align={"center"} gap={8}>
        <Typography.Text>{title}</Typography.Text>
        {tooltip ? (
          <Tooltip title={tooltip}>
            <FaInfoCircle style={{ cursor: "pointer" }} />
          </Tooltip>
        ) : null}
      </Flex>
    ),
    render: (val: any) => <ColumnBoolean value={val} />,
  };
};
