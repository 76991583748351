import { Space } from "antd";
import { WidgetChartEvolution } from "rk-react-component";
import React from "react";

interface EvolutionChartGroupProps {
    dashboardData: any;
    loading: boolean;
}

export const EvolutionChartGroup = ({
    dashboardData,
    loading
}: EvolutionChartGroupProps) => {
    return (
        <Space direction={"vertical"} style={{width: '100%'}}>
            {dashboardData.evolution_orders ? (
                <WidgetChartEvolution
                    loading={loading}
                    evolutionChart={dashboardData.evolution_orders}
                />
            ) : null}
            {dashboardData.evolution_hours ? (
                <WidgetChartEvolution
                    loading={loading}
                    evolutionChart={dashboardData.evolution_hours}
                />
            ) : null}
        </Space>
    );
};
