import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { GetOneApiReturn, useGetOneApi } from "rk-react-component";
import { UserModel } from "../../../@types/user";
import { FormUser } from "../../../containers/forms/form-user/form-user";
import { PageFormTabContainer } from "../../../containers/pages/page-form-tab-container/page-form-tab-container";
import { api } from "../../../services/api";
import { TabAddress } from "../../../containers/tabs/tab-address/tab-address";
import { TabDocument } from "../../../containers/tabs/tab-document/tab-document";
import { FormPassword } from "../../../containers/forms/form-password/form-password";

export const UserFormPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const {
    data: dataUser,
    loading: loadingUserGetOne,
  }: GetOneApiReturn<UserModel> = useGetOneApi({ url: "/users", id, api });

  return (
    <PageFormTabContainer
      title={"Usuário"}
      items={[
        {
          disabled: false,
          key: "tab_user",
          label: "Info Geral",
          loading: loadingUserGetOne,
          title: "Novo Usuário",
          FormComponent: (
            <FormUser
              initialValues={dataUser}
              onFinish={(dataApi) => navigate(`/users/${dataApi?.id}`)}
            />
          ),
        },
        {
          disabled: !dataUser?.id,
          key: "tab_address",
          label: "Endereço",
          title: "Novo Endereço",
          FormComponent: (
            <TabAddress modelId={dataUser?.id} modelType={"UsersModel"} />
          ),
        },
        {
          disabled: !dataUser?.id,
          title: "Documentos",
          label: "Documentos",
          key: "tab_document",
          FormComponent: (
            <TabDocument
              modelType={"UsersModel"}
              modelId={dataUser?.id}
              type={"documentos-do-usuario"}
            />
          ),
        },
        {
          disabled: !dataUser?.id,
          key: "tab_password",
          label: "Senha",
          loading: false,
          title: "Trocar Senha",
          FormComponent: (
            <FormPassword
              onFinish={() => {
                navigate(`/users`);
              }}
              userId={dataUser?.id}
            />
          ),
        },
      ]}
    />
  );
};
