import { Button, Card, Col, Form, Row, Typography } from "antd";
import { FormFilterItem, SelectPeriod } from "rk-react-component";
import React from "react";
import { Charts } from "rk-common";
import { FaSearch } from "react-icons/fa";

interface CardFormPeriodProps {
    onFinish: (values: any) => void;
    loading: boolean;
}

export const CardFormPeriod = ({onFinish, loading}: CardFormPeriodProps) => {
    const [form] = Form.useForm();
    return (
        <Card>
            <Typography.Title>Dashboard</Typography.Title>
            <Form form={form} onFinish={onFinish} initialValues={{range: {key: Charts.Helpers.ChartRanges.Range7}}}>
                <Row gutter={[12, 12]}>
                    <Col>
                        <SelectPeriod
                            FormItemWrap={FormFilterItem}
                            name={"range"}
                        />
                    </Col>
                    <Col>
                        <Button loading={loading} htmlType={"submit"} shape={"circle"}><FaSearch/></Button>
                    </Col>
                </Row>
            </Form>
        </Card>
    )
}