import React, { ReactNode, useMemo } from "react";
import {
    Button,
    Divider,
    notification,
    PaginationProps,
    Popconfirm,
    Space,
    Table,
    TableProps,
} from "antd";
import {
    AddIcon,
    DeleteIcon,
    EditIcon,
    GoToIcon,
} from "../../../main/icons-list";
import "./style.css";

interface TableContainerProps extends TableProps<any> {
    onEdit?: (data: any) => void;
    onDelete?: (data: any, onSuccess?: () => void, onFail?: () => void) => void;
    disabledEdit?: (data: any) => boolean;
    disabledAdd?: (data: any) => boolean;
    onGoTo?: (data: any) => void;
    onAdd?: (data: any) => void;
    pagination?: any;
    loadData?: (params: any) => void;
    buttonActions?: {
        onPress: (record: any) => void;
        icon: (record: any) => ReactNode;
        tooltip?: string;
    }[];
}

export const TableContainer = ({
                                 columns,
                                 onEdit,
                                 onGoTo,
                                 onAdd,
                                 onDelete,
                                 pagination, loadData,
                                 buttonActions,
                                 disabledEdit = () => false,
                                 disabledAdd = () => false,
                                 ...props
                               }: TableContainerProps) => {
  const columnFormatted = useMemo(() => {
    if (typeof onGoTo === "function" || typeof onEdit === "function" || typeof onAdd === "function" || buttonActions?.length || typeof onDelete === "function") {
      return [
        ...columns,
        {
          dataIndex: "action",
          key: "action",
          title: "Ação",
          render: (val, record) => (
              <Space split={<Divider type={"vertical"}/>} size={0}>
                {buttonActions?.length ? buttonActions.map((element) => (
                    <Button
                        style={{padding: 0}}
                        type="link"
                        icon={element.icon(record)}
                        onClick={() => {
                          element.onPress(record);
                        }}
                    />
                )) : null}
                {typeof onEdit === "function" && (
                    <Button
                        disabled={disabledEdit(record)}
                        style={{padding: 0}}
                        type="link"
                        icon={<EditIcon/>}
                        onClick={() => {
                          onEdit(record);
                        }}
                    />
                )}
                {typeof onDelete === "function" && (
                    <Popconfirm
                        title={'Tem certeza que deseja excluir este registro?'}
                        onConfirm={(e) => {
                            e.stopPropagation()
                          onDelete(record, () => {
                            notification.success({message: 'Registro excluído com sucesso!'})
                          });
                        }}
                        okText={'Confirmar'}
                        cancelText={'Cancelar'}
                    >
                      <Button
                          style={{padding: 0}}
                          type="link"
                          icon={<DeleteIcon/>}
                      />
                    </Popconfirm>
                )}
                {typeof onGoTo === "function" && (

                    <Button
                        style={{padding: 0}}
                        type="link"
                        icon={<GoToIcon/>}
                        onClick={(e) => {
                            e.stopPropagation()
                          onGoTo(record);
                        }}
                    />
                )}
                {(typeof onAdd === "function" && !record?.parent?.parent_id) && (
                    <Button
                        disabled={disabledAdd(record)}
                        style={{padding: 0}}
                        type="link"
                        icon={<AddIcon/>}
                        onClick={(e) => {
                            e.stopPropagation()
                          onAdd(record);
                        }}
                    />
                )}
              </Space>
          ),
        },
      ];
    }
    return columns;
  }, [columns]);
  const paginationAntd = useMemo<PaginationProps>(() => {
    return {
      per_page: pagination?.per_page || 10,
      page: pagination?.page || 1,
      current: pagination?.page || 1,
      pageSize: pagination?.per_page || 10,
      size: 'small',
      position: ['bottomCenter'],
      total: pagination?.total || 0,
      showSizeChanger: true,
      showTotal: (total: any, range: any) => `${range[0]}-${range[1]} de ${total} itens`,
      onChange: (page: any, pageSize: any) => {
        if (typeof loadData === 'function') {
          loadData({page, per_page: pageSize})
        }
      }
    } as PaginationProps
  }, [pagination])

  return (
      <Table
          rowKey={"id"}
          columns={columnFormatted}
          {...props}
          onRow={(record) => ({
              onClick: typeof onEdit == 'function' ? (event) => {
                  event.stopPropagation()
                  event.preventDefault()
                  onEdit(record)
              } : typeof onGoTo == 'function' ? (event) => {
                  event.stopPropagation()
                    event.preventDefault()
                    onGoTo(record)
              } : undefined,
          })}
          rowClassName={() => 'row-cursor-pointer'}
          size={"small"}
          scroll={{x: true}}
          pagination={paginationAntd}
      />
  );
};
