const gray = '#5e6f84'
const pink = '#ff5959'
const yellow = '#ffd527'
export const configProject = {
  colorPrimary: pink,
  colorLink: pink,
  colorMenuBackground: '#2f3031',
  // colorMenuBackground: "#5e6f84",
  colorTextMenu: "white",
  colorTextMenuSelected: pink,
  colorBgHeader: gray,
  colorMenuBackgroundSelected: pink,
};
