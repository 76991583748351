import {
    formatDate,
    FormFilterItem,
    SelectPeriod,
    useGetAllApi,
    useGetOneApi,
} from "rk-react-component";
import { useNavigate, useParams } from "react-router-dom";
import { api } from "../../../services/api";
import {
    Button,
    Card,
    Col,
    Divider,
    Form,
    Row,
    Space,
    Statistic,
    Typography,
} from "antd";
import { PageHeader } from "../../../components/page-header/page-header";
import React, { useEffect } from "react";
import { TableVolunteerOrder } from "../../../containers/tables/table-volunteer-order/table-volunteer-order";
import { useFetchDashboard } from "../../dashboard/use-fetch-dashboard";
import { WidgetComponent } from "../../dashboard/widget-component";
import { PieChartGroup } from "../../dashboard/pie-chart-group";
import { EvolutionChartGroup } from "../../dashboard/evolution-chart-group";
import { Charts } from "rk-common";
import { FaSearch } from "react-icons/fa";

export const VolunteerViewPage = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const { data, loading } = useGetOneApi<any>({
        url: "/volunteers",
        id,
        api,
    });

    const {
        dataList,
        loading: loadingOrders,
        fetchDataList,
        pagination,
    } = useGetAllApi<any, any>({
        url: "/orders",
        api,
        initialRequest: false,
    });

    useEffect(() => {
        if (data?.id) {
            fetchDataList({ volunteer_id: data?.user_id });
        }
    }, [data]);

    const {
        dashboard,
        fetchDashboard,
        loading: loadingDashboard,
    } = useFetchDashboard({
        type: "company-volunteer",
        fixParams: { volunteer_id: id },
    });

    const onBack = () => navigate(-1);
    return (
        <Space style={{ width: "100%" }} direction={"vertical"}>
            <PageHeader title={"Voluntário"} onBack={onBack} />
            <Divider />
            <Card loading={loading} size={"small"}>
                <Space split={<Divider type={"vertical"} />}>
                    <Statistic title={"Voluntário"} value={data?.user_name} />
                    <Statistic title={"Empresa"} value={data?.company_name} />
                    <Statistic
                        title={"Data da Entrevista"}
                        value={formatDate(data?.date)}
                    />
                </Space>
            </Card>
            <TableVolunteerOrder
                dataSource={dataList}
                loading={loadingOrders}
                pagination={pagination}
                loadData={fetchDataList}
            />
            <Card>
                <Space>
                    <Form
                        form={form}
                        onFinish={fetchDashboard}
                        initialValues={{
                            range: { key: Charts.Helpers.ChartRanges.Range7 },
                        }}
                    >
                        <Row gutter={[12, 12]}>
                            <Col>
                                <Typography.Title
                                    level={4}
                                    style={{ marginTop: 0 }}
                                >
                                    Painel
                                </Typography.Title>
                            </Col>
                            <Col>
                                <SelectPeriod
                                    FormItemWrap={FormFilterItem}
                                    name={"range"}
                                />
                            </Col>
                            <Col>
                                <Button
                                    loading={loading}
                                    htmlType={"submit"}
                                    shape={"circle"}
                                >
                                    <FaSearch />
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </Space>
            </Card>
            <WidgetComponent
                dashboardData={dashboard}
                loading={loadingDashboard}
            />
            <PieChartGroup
                dashboardData={dashboard}
                loading={loadingDashboard}
            />
            <EvolutionChartGroup
                dashboardData={dashboard}
                loading={loadingDashboard}
            />
        </Space>
    );
};