import { Col, Row } from "antd";
import { WidgetChartPie } from "rk-react-component";
import React from "react";

interface PieChartGroupProps {
    dashboardData: any;
    loading: boolean;
}
export const PieChartGroup = ({dashboardData, loading}: PieChartGroupProps) => {
    return (
        <Row gutter={[12,12]}>
            {dashboardData.pie_orders_by_skill ? (
                <Col span={12}>
                    <WidgetChartPie
                        loading={loading}
                        cardProps={{}}
                        chartPie={dashboardData.pie_orders_by_skill}
                    />
                </Col>
            ) : null}
            {dashboardData.pie_orders_hours_by_skill ? (
                <Col span={12}>
                    <WidgetChartPie
                        loading={loading}
                        cardProps={{}}
                        chartPie={dashboardData.pie_orders_hours_by_skill}
                    />
                </Col>
            ) : null}
        </Row>
    )
}