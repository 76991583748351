import React from "react";
import { useGetAllApi } from "rk-react-component";
import { PageIndexContainer } from "../../../containers/pages/page-index-container/page-index-container";
import { api } from "../../../services/api";
import { TableVolunteer } from "../../../containers/tables/table-volunteer/table-volunteer";
import { FormFilterVolunteer } from "../../../containers/form-filter/form-filter-volunteer/form-filter-volunteer";
import { useNavigate } from "react-router-dom";

export const VolunteerMasterIndexPage = () => {
    const navigate = useNavigate();
    const { dataList, fetchDataList, loading, setDataList, pagination } =
        useGetAllApi<any, any>({
            url: "/volunteers",
            api,
        });

    return (
        <PageIndexContainer
            url={"/volunteers"}
            title={"Voluntários"}
            btnTitle={"Novo Voluntário"}
            showBtnAdd={false}
            FormFilterComponent={
                <FormFilterVolunteer onFinish={(data) => fetchDataList(data)} />
            }
            TableComponent={() => (
                <TableVolunteer
                    onGoTo={(record) => navigate(`/volunteers/${record.id}`)}
                    loadData={fetchDataList}
                    pagination={pagination}
                    dataSource={dataList}
                    loading={loading}
                    setDataList={setDataList}
                />
            )}
        />
    );
};
