import React, { ReactNode } from "react";
import { Space } from "antd";
import { WidgetComponent } from "./widget-component";
import { useFetchDashboard } from "./use-fetch-dashboard";
import { CardFormPeriod } from "./card-form-period";
import { PieChartGroup } from "./pie-chart-group";
import { EvolutionChartGroup } from "./evolution-chart-group";

interface DashboardPageProps {
    type: "company" | "master" | "osc";
}
export const DashboardPage = ({type}: DashboardPageProps): ReactNode => {
    const { dashboard, fetchDashboard, loading } = useFetchDashboard({ type });
    return (
        <Space direction={"vertical"} style={{ width: "100%" }}>
            <CardFormPeriod onFinish={fetchDashboard} loading={loading}/>
            <WidgetComponent dashboardData={dashboard} loading={loading}/>
            <PieChartGroup dashboardData={dashboard} loading={loading}/>
            <EvolutionChartGroup dashboardData={dashboard} loading={loading}/>
        </Space>
    );
};
