import { Divider, Typography } from "antd";
import packageJson from "../../package.json";
import React, { ReactNode } from "react";

interface FooterLayoutProps {
  collapsed: boolean;
}

export const FooterLayout = ({ collapsed }: FooterLayoutProps): ReactNode => {
  return (
    <>
      <Divider />
      {collapsed ? (
        <Typography.Text
          strong
          style={{
            fontSize: 8,
            color: "white",
            textAlign: "center",
            width: "100%",
            display: "block",
          }}
        >
          {`${process.env.REACT_APP_RELEASE} \n RK (${packageJson?.dependencies?.["rk-react-component"]})`}
        </Typography.Text>
      ) : (
        <Typography.Text
          strong
          style={{
            color: "white",
            textAlign: "center",
            width: "100%",
            display: "block",
          }}
        >
          {`${process.env.REACT_APP_RELEASE} - RK (${packageJson?.dependencies?.["rk-react-component"]})`}
        </Typography.Text>
      )}
    </>
  );
};
