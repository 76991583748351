import React from "react";
import "./style.css";
import { OrderModel } from "../../../@types/order";
import { Space, Tooltip, Typography } from "antd";
import { useNavigate } from "react-router-dom";
import { TableContainer } from "../table-container/table-container";
import moment from "moment";
import { TagConfigOption } from "../../../components/tag-config-option/tag-config-option";
import { ONGIcon, VolunteerIcon } from "../../../main/icons-list";
import _ from "lodash";
import { TagStatusOrder } from "../../../components/tag-status-order/tag-status-order";

interface TableOrderProps {
    dataSource: OrderModel[];
    loading: boolean;
    loadData: (params: any) => void;
    pagination: any;
}

export const TableVolunteerOrder = ({
    dataSource,
    loading,
    loadData,
    pagination,
}: TableOrderProps) => {
    const navigate = useNavigate();

    return (
        <>
            <TableContainer
                loadData={loadData}
                pagination={pagination}
                bordered={true}
                id={"table_volunteer_order"}
                loading={loading}
                columns={[
                    {
                        dataIndex: "title",
                        key: "title",
                        title: "Título",
                        render: (val) => (
                            <div style={{ minWidth: 320 }}>
                                <Typography.Text strong>{val}</Typography.Text>
                            </div>
                        ),
                    },
                    {
                        dataIndex: "status",
                        key: "status",
                        title: "Status",
                        render: (val) => <TagStatusOrder status={val} />,
                    },
                    {
                        dataIndex: "skill",
                        key: "skill",
                        title: "Habilidade",
                        render: (val, record) => (
                            <TagConfigOption
                                configOption={record?.config_option_skill}
                            />
                        ),
                    },
                    {
                        dataIndex: "audience",
                        key: "audience",
                        title: "Público",
                        render: (val, record) => (
                            <TagConfigOption
                                configOption={record?.config_option_audience}
                            />
                        ),
                    },
                    {
                        dataIndex: "date",
                        key: "date",
                        title: "Data",
                        render: (val) =>
                            val ? moment(val).format("DD/MM/YYYY HH:mm") : "-",
                    },
                    {
                        dataIndex: "expected_hours",
                        key: "expected_hours",
                        title: "Horas Previstas",
                        align: "right",
                        render: (val) => (val || "-") + " /h",
                    },
                    {
                        dataIndex: "expected_people",
                        key: "expected_people",
                        title: "Expectativa de pessoas atendidas/voluntário",
                        align: "right",
                        render: (val) => val || '-'
                    },
                    {
                        dataIndex: 'volunteer',
                        key: 'volunteer',
                        title: 'Voluntário',
                        render: (val: any, record: any) => {
                                return (
                                    <div style={{minWidth: 200}}>
                                        <Typography.Text strong>
                                            {record?.volunteer?.name || "-"}
                                        </Typography.Text>
                                    </div>
                                );

                        }
                    },
                    {
                        dataIndex: "rating",
                        key: "rating",
                        title: "Avaliação",
                        ellipsis: true,
                        render: (_v, record) => (
                            <Space
                                direction={"vertical"}
                                align={"end"}
                                style={{ maxWidth: 100 }}
                            >
                                <Space>
                                    <VolunteerIcon />{" "}
                                    {record?.rating_by_volunter || "-"}
                                </Space>
                                <Space>
                                    <ONGIcon />{" "}
                                    {record?.rating_by_company || "-"}
                                </Space>
                            </Space>
                        ),
                    },
                    {
                        dataIndex: "comment",
                        key: "comment",
                        title: "Comentário",
                        ellipsis: true,
                        width: "200px",
                        render: (_v, record) => (
                            <Space
                                direction={"vertical"}
                                align={"start"}
                                style={{ maxWidth: 200 }}
                            >
                                <Tooltip
                                    title={record?.rating_comment_by_volunter}
                                >
                                    <Space>
                                        <VolunteerIcon />
                                        {_.truncate(
                                            record?.rating_comment_by_volunter ||
                                                "-",
                                            { length: 20 },
                                        )}
                                    </Space>
                                </Tooltip>
                                <Tooltip
                                    title={record?.rating_comment_by_company}
                                >
                                    <Space>
                                        <ONGIcon />
                                        {_.truncate(
                                            record?.rating_comment_by_company ||
                                                "-",
                                            { length: 20 },
                                        )}
                                    </Space>
                                </Tooltip>
                            </Space>
                        ),
                    },
                ]}
                dataSource={dataSource}
            />
        </>
    );
};
