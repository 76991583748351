import React from "react";
import { FormFilterContainer, FormFilterItem } from "rk-react-component";
import { Form, Input } from "antd";
import { SelectBoolean } from "../../inputs/select-boolean/select-boolean";

interface FormFilterCompanyProps {
    onFinish: (data: any) => void;
    initialValues?: any;
}

export const FormFilterCompany = ({ onFinish, initialValues }: FormFilterCompanyProps) => {
  const [form] = Form.useForm();
  return (
    <FormFilterContainer
      formProps={{
        onFinish: (dataForm) => {
          onFinish({
            ...dataForm,
            has_volunter_program:
              dataForm.has_volunter_program === "1"
                ? true
                : dataForm.has_volunter_program === "0"
                ? false
                : undefined,
            has_esg_program:
              dataForm.has_esg_program === "1"
                ? true
                : dataForm.has_esg_program === "0"
                ? false
                : undefined,
            has_social_responsibility_policy:
              dataForm.has_social_responsibility_policy === "1"
                ? true
                : dataForm.has_social_responsibility_policy === "0"
                ? false
                : undefined,
          });
        },
        initialValues: {
            ...initialValues,
          has_volunter_program:
            initialValues?.has_volunter_program === true
              ? "1"
              : initialValues?.has_volunter_program === false
              ? "0"
              : undefined,
          has_esg_program:
            initialValues?.has_esg_program === true
              ? "1"
              : initialValues?.has_esg_program === false
              ? "0"
              : undefined,
          has_social_responsibility_policy:
            initialValues?.has_social_responsibility_policy === true
              ? "1"
              : initialValues?.has_social_responsibility_policy === false
              ? "0"
              : undefined,
        },
      }}
      form={form}
      hiddenShowMore={true}
    >
      {() => (
        <>
          <FormFilterItem name={"name_lk"}>
            <Input placeholder={"Nome"} />
          </FormFilterItem>
          <FormFilterItem name={"has_volunter_program"}>
            <SelectBoolean
              style={{ width: 200 }}
              placeholder={"Programa de Voluntáriado?"}
            />
          </FormFilterItem>
          <FormFilterItem name={"has_esg_program"}>
            <SelectBoolean
              style={{ width: 200 }}
              placeholder={"Possui programa de ESG?"}
            />
          </FormFilterItem>
          <FormFilterItem name={"has_social_responsibility_policy"}>
            <SelectBoolean
              style={{ width: 200 }}
              placeholder={"Responsabilidade Social??"}
            />
          </FormFilterItem>
        </>
      )}
    </FormFilterContainer>
  );
};
