import { api } from "../../../services/api";
import React, { useEffect, useState } from "react";
import { useGetAllApi } from "rk-react-component";
import { TableDocument } from "../../tables/table-document/table-document";
import { Button, Modal, Space } from "antd";
import { FormDocument } from "../../forms/form-document/form-document";

interface TabAddressProps {
  modelType: string;
  modelId: number;
  type: "documentos-da-empresa" | "documentos-da-osc" | "documentos-do-usuario";
}

export const TabDocument = ({ modelType, modelId, type }: TabAddressProps) => {
  const [modal, setModal] = useState(false)
  const { dataList, fetchDataList, loading, setDataList, remove, pagination } = useGetAllApi<any, any>({
    url: '/medias',
    initialRequest: false,
    fixParams: { model_id: modelId, model_type: modelType, type},
    api,
  })

  useEffect(() => {
    if (modelId) {
      fetchDataList({ page: 1, per_page: 1 }, () => {
      })
    }
  }, [modelId])
  return (
      <Space direction={"vertical"} style={{width: '100%'}}>
        <Button onClick={() => {setModal(true)}} type={'primary'}>Novo Documento</Button>
        <TableDocument dataSource={dataList} loading={loading} onDelete={remove} pagination={pagination}/>
        <Modal destroyOnClose={true} open={modal} onCancel={() => setModal(false)} footer={false}>
          <FormDocument
              onBack={() => setModal(false)}
              modelId={modelId}
              modelType={modelType}
              type={type}
              onFinish={(response) => {
                  setDataList([...dataList, response])
                  setModal(false)
              }}
          />
        </Modal>
      </Space>
  )
}
