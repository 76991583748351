import { Typography } from "antd";
import React from "react";
import { TableContainer } from "../table-container/table-container";
import { UserModel } from "../../../@types/user";

interface TableUserProps {
    dataSource: UserModel[];
    loading: boolean;
    onEdit: any;
    loadData?: any;
    pagination?: any;
}

export const TableUser = ({
    dataSource,
    loading,
    onEdit,
    loadData,
    pagination,
}: TableUserProps) => {
    return (
        <TableContainer
            pagination={pagination}
            loadData={loadData}
            onEdit={onEdit}
            id={"table_user"}
            loading={loading}
            columns={[
                {
                    key: "name",
                    dataIndex: "name",
                    title: "Nome",
                    render: (val) => (
                        <Typography.Text strong>{val}</Typography.Text>
                    ),
                },
                {
                    key: "email",
                    dataIndex: "email",
                    title: "Email",
                    render: (val) => (
                        <Typography.Text strong>{val}</Typography.Text>
                    ),
                },
            ]}
            dataSource={dataSource}
        />
    );
};
