import React, { useEffect, useState } from "react";
import { MainLayout } from "./layouts/main-layout/main-layout";
import { MeContext } from "../contexts/me-context/me-context";
import { FilterContext } from "rk-react-component";
import "moment/locale/pt-br";

function App() {
    const [me, setMe] = useState(null);
    const [role, setRole] = useState(null);
    const [ready, setReady] = useState(false);
    const [filter, setFilter] = useState({
        masterIndex: {},
    });
    useEffect(() => {
        const meAux = localStorage.getItem("user");
        setMe(meAux ? JSON.parse(meAux) : JSON.stringify({ id: null }));
        const roleAux = localStorage.getItem("active_role");
        setRole(roleAux ? JSON.parse(roleAux) : JSON.stringify({ id: null }));
        setReady(true);
    }, []);

    if (!ready) return null;
    return (
        <MeContext.Provider
            value={{ role, me: me, setRole, setMe: setMe, ready, setReady }}
        >
            <FilterContext.Provider value={{ filter, setFilter }}>
                <MainLayout />
            </FilterContext.Provider>
        </MeContext.Provider>
    );
}

export default App;
