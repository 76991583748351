import React from "react";
import { PageIndexContainer } from "../../../containers/pages/page-index-container/page-index-container";
import { useGetAllApi } from "rk-react-component";
import { TableMedia } from "../../../containers/tables/table-media/table-media";
import { FormFilterMedia } from "../../../containers/form-filter/form-filter-media/form-filter-cabinet";
import { api } from "../../../services/api";

export const MediaIndexPage = () => {
  const { dataList, loading, fetchDataList } = useGetAllApi<any, any>({
    url: "/medias",
    api,
  });

  return (
    <PageIndexContainer
      url={"/medias"}
      title={"Mídias"}
      btnTitle={"Nova Mídia"}
      FormFilterComponent={() => (
        <FormFilterMedia onFinish={(data) => fetchDataList(data)} />
      )}
      TableComponent={() => (
        <TableMedia dataSource={dataList} loading={loading} />
      )}
    />
  );
};
