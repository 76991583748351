import React, { useEffect } from "react";
import { Button, Modal, Space } from "antd";
import { useGetAllApi } from "rk-react-component";
import { api } from "../../../services/api";
import { TableRoleUser } from "../../tables/table-role-user/table-role-user";
import { FormRoleUser } from "../../forms/form-role-user/form-role-user";
import { FormFilterRoleUser } from "../../form-filter/form-filter-role-user/form-filter-role-user";

interface ModalAddRoleProps {
  btnTitle?: string;
  fixParams: {
    role: "MASTER" | "COMPANY_MANAGER" | "OSC_MANAGER" | "VOLUNTEER";
    allow_model_type: "CompanyModel";
    allow_model_id: number;
  };
}

const modalInitialState = {visible: false, data: null}

export const ModalAddRole = ({fixParams, btnTitle = 'Novo Responsável'}: ModalAddRoleProps) => {
    const [modal, setModal] = React.useState(modalInitialState)
    const {
        dataList: dataResponsible,
        fetchDataList: fetchDataResponsible,
        loading: loadingResponsible,
        pagination,
        remove
    } =
        useGetAllApi<any, any>({
            url: '/roles',
            fixParams,
            api, initialRequest: false
        })
    useEffect(() => {
        fetchDataResponsible({per_page: 10, page: 1})
    }, []);
    return (
        <>
            <Space style={{width: '100%'}} direction={'vertical'}>
                <Button type={'primary'} onClick={() => setModal({visible: true, data: null})}>
                    {btnTitle}
                </Button>
                <FormFilterRoleUser onFinish={(dataForm) => {
                    fetchDataResponsible({page: 1, per_page: 10, ...dataForm})
                }}/>
                <TableRoleUser
                    role={''}
                    onDelete={remove}
                    loading={loadingResponsible}
                    dataSource={dataResponsible}
                    loadData={fetchDataResponsible}
                    pagination={pagination}
                />
            </Space>
            <Modal
                open={modal.visible}
                onCancel={() => setModal(modalInitialState)}
                width={'90%'}
                footer={false}
                closeIcon={false}
                destroyOnClose={true}
            >
                <FormRoleUser
                    onFinish={() => {
                        fetchDataResponsible({page:1, per_page: 10})
                        setModal(modalInitialState)
                    }}
                    role={fixParams?.role}
                    modelId={fixParams?.allow_model_id}
                    modelType={fixParams?.allow_model_type}
                />
            </Modal>
        </>
    )
}
