import {
  FaBuilding,
  FaCogs,
  FaEdit, FaEye,
  FaHandsHelping,
  FaHeart,
  FaSearch,
  FaShoppingCart,
  FaSignOutAlt, FaTrash,
  FaUser,
} from "react-icons/fa";
import {MdDashboard} from "react-icons/md";
import {TiTickOutline} from "react-icons/ti";

// MODULES
export const DashboardIcon = MdDashboard
export const MasterIcon = FaCogs
export const ConfigOptionIcon = FaCogs
export const CompanyIcon = FaBuilding
export const OrderIcon = FaShoppingCart
export const VolunteerIcon = FaHandsHelping
export const ProfileIcon = FaUser
export const ONGIcon = FaHeart
// OTHERS
export const EditIcon = FaEdit
export const DeleteIcon = FaTrash
export const SearchIcon = FaSearch
export const LogoutIcon = FaSignOutAlt
export const PublishIcon = TiTickOutline

export const AddIcon = FaUser
export const GoToIcon = FaEye
