import { FormFilterContainer } from "../form-filter-container/form-filter-container";
import React from "react";
import { FormFilterItem } from "rk-react-component";
import { Form, Input } from "antd";

interface FormFilterMediaProps {
  onFinish: (data: any) => void;
}

export const FormFilterMedia = ({ onFinish }: FormFilterMediaProps) => {
  const [form] = Form.useForm();
  return (
    <FormFilterContainer onFinish={onFinish} form={form}>
      <FormFilterItem name={"name"}>
        <Input placeholder={"Nome"} />
      </FormFilterItem>
    </FormFilterContainer>
  );
};
