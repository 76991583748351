import { Switch } from "antd";
import React, { useState } from "react";
import { api } from "../../../services/api";

export const SwitchRole = ({ value, roleId, onUpdate }) => {
    const [loading, setLoading] = useState(false);
    return (
        <Switch
            value={value}
            loading={loading}
            onChange={() => {
                setLoading(true);
                api.put(`/roles/${roleId}`, { active: !value })
                    .then(() => {
                        onUpdate(!value);
                    })
                    .catch(() => {})
                    .finally(() => {
                        setLoading(false);
                    });
            }}
        />
    );
};
