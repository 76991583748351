import { ColorPicker, Divider, Form, Input } from "antd";
import React from "react";
import { FormContainer, FormItem } from "rk-react-component";
import { TagConfigOption } from "../../../components/tag-config-option/tag-config-option";

interface Props {
    onFinish: any;
    onBack: any;
    initialValues?: any;
    loading: boolean;
}

export const FormConfigOption: React.FC<Props> = ({onFinish, initialValues, onBack, loading}) => {
    const [form] = Form.useForm()
    const name = Form.useWatch('name', form)
    const back_color = Form.useWatch('back_color', form)
    const front_color = Form.useWatch('front_color', form)
    return (
        <FormContainer
            labelCol={{ span: 24 }}
            onBack={onBack}
            loading={loading}
            initialValues={{...initialValues, back_color: initialValues?.back_color || '#000000', front_color: initialValues?.front_color || '#ffffff'}}
            form={form}
            onFinish={onFinish}
        >
            <FormItem
                label={'Nome'}
                name={"name"}
            >
                <Input/>
            </FormItem>
            <FormItem
                label={'Tipo'}
                name={"type"}
            >
                <Input disabled={true}/>
            </FormItem>
            <FormItem
                label="Nome curto"
                name={"short_name"}
            >
                <Input/>
            </FormItem>
            <FormItem
                label="Cor de Fundo"
                name={"back_color"}
                getValueFromEvent={(value) => {
                    return value?.toHexString?.() || value
                }}
            >
                <ColorPicker showText/>
            </FormItem>
            <FormItem
                label="Cor do Texto"
                name={"front_color"}
                getValueFromEvent={(value) => {
                    return value?.toHexString?.() || value
                }}
            >
                <ColorPicker showText/>
            </FormItem>
            <Divider>Exemplo</Divider>
            <TagConfigOption configOption={{name, back_color, front_color}}/>

        </FormContainer>
    )
}
