import { api } from "../../services/api";
import logoFull from "../../assets/logo.png";
import { ResetPasswordPage } from "rk-react-component";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";

export const ResetPasswordFactory = () => {
    const navigate = useNavigate();
    const { code } = useParams();
    console.log(code);
    return (
        <ResetPasswordPage
            code={code}
            api={api}
            logo={logoFull}
            logoProps={{ style: { width: 280 } }}
            goLogin={() => navigate("/signin")}
        />
    );
};