import React from "react";
import {
    FormContainer,
    FormItem,
    InputZipcode,
    useFormApi,
} from "rk-react-component";
import { Form, Input } from "antd";
import { api } from "../../../services/api";
import { notificationSuccessSave } from "../../../services/notification-success-save";

interface FormAddressProps {
    onFinish: (data: any) => void;
    initialValues?: Partial<any>;
    modelId: string | number;
    modelType: string;
}

export const FormAddress = ({ onFinish, initialValues, modelId, modelType }: FormAddressProps) => {
  const [form] = Form.useForm()
  const { save, loading } = useFormApi<any, any>({
    url: '/addresses',
    id: initialValues?.id,
    fixParams: { name: 'Default', model_id: modelId, model_type: modelType },
    api,
  })

  return (
      <FormContainer
          labelCol={{ span: 24 }}
          name={'form_address'}
          initialValues={initialValues}
          form={form}
          onFinish={(data) => {
            save(
                data,
                (response) => {
                  onFinish(response)
                  notificationSuccessSave(!initialValues?.id)
                },
                (e) => console.warn(e),
            )
          }}
          loading={loading}
      >
        <FormItem label={'Rua'} name={'street'} rules={[{ required: true, message: 'O campo "Rua" é obrigatório.' }]}>
          <Input placeholder={'Rua'} data-testid={'street'} />
        </FormItem>
        <FormItem
            label={'Bairro'}
            name={'district'}
            rules={[{ required: true, message: 'O campo "Bairro" é obrigatório.' }]}
        >
          <Input placeholder={'Bairro'} data-testid={'district'} />
        </FormItem>
        <FormItem label={'Cidade'} name={'city'} rules={[{ required: true, message: 'O campo "Cidade" é obrigatório.' }]}>
          <Input placeholder={'Cidade'} data-testid={'city'} />
        </FormItem>
        <FormItem
            label={'Número'}
            name={'number'}
            rules={[{ required: true, message: 'O campo "Número" é obrigatório.' }]}
        >
          <Input placeholder={'Número'} data-testid={'number'} />
        </FormItem>
        <FormItem label={'CEP'} name={'zipcode'} rules={[{ required: true, message: 'O campo "CEP" é obrigatório.' }]}>
          <InputZipcode inputRef={null} data-testid={'zipcode'} />
        </FormItem>
        <FormItem
            label={'Estado'}
            name={'state'}
            rules={[{ required: true, message: 'O campo "Estado" é obrigatório.' }]}
        >
          <Input placeholder={'Estado'} data-testid={'state'} />
        </FormItem>
      </FormContainer>
  )
}
