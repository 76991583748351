import React, { useMemo } from "react";
import { WidgetChartMetrics } from "rk-react-component";

interface WidgetComponentProps {
    dashboardData: any;
    loading: boolean;
}

export const WidgetComponent = ({ dashboardData, loading }: WidgetComponentProps) => {
    // TODO Fazer uma iteração para pegar todos os metrics
    const metrics = useMemo(() => {
        const metricsAux = [];
        if (dashboardData?.metric_hours) {
            metricsAux.push(dashboardData?.metric_hours);
        }
        if (dashboardData?.metric_orders) {
            metricsAux.push(dashboardData?.metric_orders);
        }
        if (dashboardData?.metric_volunteers) {
            metricsAux.push(dashboardData?.metric_volunteers);
        }
        return metricsAux;
    }, [dashboardData?.metric_hours, dashboardData?.metric_orders, dashboardData?.metric_volunteers]);

    return <WidgetChartMetrics chartMetrics={metrics}  loading={loading}/>;
};
