import React, { useEffect, useState } from "react";
import { Button, Card, Divider, Modal, Spin, Tabs } from "antd";
import { useNavigate } from "react-router-dom";
import _ from "lodash";
import { useFormApi, useGetAllApi } from "rk-react-component";
import { api } from "../../../services/api";
import { FormConfigOption } from "../../../containers/forms/form-config-option/form-config-option";
import { PageHeader } from "../../../components/page-header/page-header";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import { TagConfigOption } from "../../../components/tag-config-option/tag-config-option";
import { TableContainer } from "../../../containers/tables/table-container/table-container";

const modalInitialState = { visible: false, data: null };

export const ConfigOptionIndexPage: React.FC = () => {
  const navigate = useNavigate();
  const {
    dataList: dataListChildren,
    loading: loadingChildren,
    fetchDataList: fetchDataListChildren,
    pagination,
  } = useGetAllApi<any, any>({
    fixParams: {},
    url: `/config-options`,
    initialRequest: false,
    api,
  });
  const {
    dataList,
    fetchDataList: fetchDataListParent,
    loading: loadingParent,
  } = useGetAllApi<any, any>({
    fixParams: {},
    url: "/config-options",
    api,
    initialRequest: false,
  });
  useEffect(() => {
    fetchDataListParent({ type: "configuracoes" }, (data: any) => {
      if (data.length) {
        setTabSelected(data[0]);
        fetchDataListChildren({ type: data[0].slug }, () => {});
      }
    });
  }, []);
  const [showModal, setShowModal] = useState(modalInitialState);
  const { save } = useFormApi<any, any>({
    url: "/config-options",
    api,
    id: showModal?.data?.id || undefined,
  });
  const [tabSelected, setTabSelected] = useState<any>({ id: "" });
  const { width } = useWindowDimensions();

  return (
    <Spin spinning={loadingParent}>
      <PageHeader
        title={`Configurações - (${tabSelected?.name || "Selecione uma Tab"})`}
        onBack={() => navigate(-1)}
      />
      <Divider />
      <Tabs
        tabPosition={width < 500 ? "top" : "left"}
        type={"card"}
        activeKey={tabSelected.id + ""}
        onTabClick={(active) => {
          const optKey: any = _.mapKeys(dataList, "id");
          setTabSelected(optKey[active]);
          fetchDataListChildren({ type: optKey[active].slug });
        }}
      >
        {dataList.length &&
          dataList.map((config: any) => (
            <Tabs.TabPane tab={config.name} key={config.id}>
              <Card
                size={"small"}
                extra={[
                  <Button
                    style={{ marginBottom: 0 }}
                    type={"primary"}
                    size={"small"}
                    onClick={() => setShowModal({ visible: true, data: {} })}
                  >
                    Adicionar
                  </Button>,
                ]}
              >
                <TableContainer
                  loading={loadingParent || loadingChildren}
                  loadData={(dataP) => {
                      fetchDataListChildren({...dataP, type: tabSelected?.slug})
                  }}
                  pagination={pagination}
                  columns={[
                    {
                      key: "id",
                      dataIndex: "id",
                      title: "Cod.",
                      render: (val, record) => (
                        <Button
                          type={"link"}
                          onClick={() => {
                            setShowModal({ visible: true, data: record });
                          }}
                        >
                          OPT-{val}
                        </Button>
                      ),
                    },
                    {
                      key: "name",
                      dataIndex: "name",
                      title: "Nome",
                    },
                    {
                      key: "tag",
                      dataIndex: "tag",
                      title: "Tag",
                      render: (_val, record) => (
                        <TagConfigOption configOption={record}/>
                      ),
                    },
                  ]}
                  dataSource={dataListChildren.map((i) => ({
                    ...i,
                    children: i?.children?.length ? i?.children : null,
                  }))}
                />
              </Card>
            </Tabs.TabPane>
          ))}
      </Tabs>
      <Modal
        destroyOnClose={true}
        title={"Nova Opção"}
        open={showModal?.visible}
        footer={null}
        onCancel={() => setShowModal(modalInitialState)}
      >
        <FormConfigOption
          loading={loadingParent || loadingChildren}
          initialValues={{ ...showModal?.data, type: tabSelected?.slug }}
          onBack={() => {
            setShowModal(modalInitialState);
          }}
          onFinish={(data: any) => {
            save(
              {
                ...data,
                parent_id: tabSelected.id,
                type: tabSelected.slug,
                slug: "slug",
              },
              () => {
                setShowModal(modalInitialState);
                fetchDataListChildren({ type: tabSelected.slug });
              },
              () => {},
            );
          }}
        />
      </Modal>
    </Spin>
  );
};
