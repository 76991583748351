import { PageHeader } from "../../../components/page-header/page-header";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { formatDate, useGetOneApi } from "rk-react-component";
import { api } from "../../../services/api";
import {
    Card,
    Col,
    Divider,
    Empty,
    Flex,
    Rate,
    Row,
    Space,
    Spin,
    Statistic,
    Typography,
} from "antd";
import { TagConfigOption } from "../../../components/tag-config-option/tag-config-option";
import { FaBusinessTime, FaCalendarDay, FaUser } from "react-icons/fa";
import { formatAddress } from "../../../services/format";
import { TagStatusOrder } from "../../../components/tag-status-order/tag-status-order";
import { OrderStatusAction } from "../../../components/order-status-action/order-status-action";
import { OrderStatusEnum } from "../../../@types/order-status-enum";
import { ModalRating } from "../../../containers/modals/modal-rating/modal-rating";
import { FaMapLocationDot } from "react-icons/fa6";
import { TfiCommentsSmiley } from "react-icons/tfi";

export const OrderViewPage = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const { data, loading, setLoading, setData } = useGetOneApi<any>({
        url: "/orders",
        id,
        api,
    });
    return (
        <Spin spinning={loading}>
            <PageHeader
                extra={
                    <OrderStatusAction
                        order={data}
                        setLoading={setLoading}
                        setData={setData}
                    />
                }
                title={
                    <Typography.Text strong style={{ fontSize: 24 }}>
                        {data?.title}
                    </Typography.Text>
                }
                subTitle={
                    <Flex align={"center"} gap={24}>
                        <FaCalendarDay />
                        <Typography.Text
                            strong
                            style={{ color: "#5b5b5b", fontSize: 18 }}
                        >
                            {formatDate(data?.date, "DD/MM/YYYY HH:mm")}
                        </Typography.Text>
                    </Flex>
                }
                onBack={() => navigate(-1)}
            />

            <Space style={{ width: "100%", marginTop: 12, paddingLeft: 24 }}>
                <Statistic
                    title={"Status"}
                    valueRender={() => <TagStatusOrder status={data?.status} />}
                />
                <Statistic
                    title={"Público"}
                    valueRender={() => (
                        <TagConfigOption
                            configOption={data?.config_option_audience}
                        />
                    )}
                />
                <Statistic
                    title={"Habilidade"}
                    valueRender={() => (
                        <TagConfigOption
                            configOption={data?.config_option_skill}
                        />
                    )}
                />
                <Divider type={"vertical"} />
                <Statistic
                    title={"Horas Previstas"}
                    valueRender={() =>
                        data?.expected_hours ? `${data?.expected_hours}h` : "-"
                    }
                />
                <Divider type={"vertical"} />
                <Statistic
                    title={"Check-IN"}
                    valueRender={() =>
                        formatDate(data?.checkin_at, "DD/MM/YYYY HH:mm") || "-"
                    }
                />
                <Divider type={"vertical"} />
                <Statistic
                    title={"Check-OUT"}
                    valueRender={() =>
                        formatDate(data?.checkout_at, "DD/MM/YYYY HH:mm") || "-"
                    }
                />
            </Space>
            <Divider />
            <Row gutter={[24, 12]}>
                <Col span={24}>
                    <Card title={"Descrição da Atividade"} size={"small"}>
                        <Typography.Paragraph
                            style={{ fontSize: 18 }}
                            type={"secondary"}
                        >
                            {data?.description}
                        </Typography.Paragraph>
                    </Card>
                </Col>
                <Col span={24}>
                    <Card title={"Endereço"} size={"small"}>
                        <Flex align={"center"} gap={12}>
                            <FaMapLocationDot />
                            <Typography.Text
                                style={{ fontSize: 18 }}
                                type={"secondary"}
                            >
                                {formatAddress(data?.address)}
                            </Typography.Text>
                        </Flex>
                    </Card>
                </Col>
                <Col span={12}>
                    <Card title={"OSC"} size={"small"}>
                        <Space direction={"vertical"} size={12}>
                            <Flex justify={"space-between"}>
                                <Typography.Text
                                    strong
                                    style={{ fontSize: 18 }}
                                >
                                    {data?.requester_company?.name}
                                </Typography.Text>
                                {data?.status === OrderStatusEnum.FINISHED ? (
                                    <Rate
                                        onChange={() => {}}
                                        value={data?.rating_by_volunter || 0}
                                        disabled
                                    />
                                ) : null}
                            </Flex>
                            <Typography.Text
                                style={{ fontSize: 14 }}
                                type={"secondary"}
                            >
                                {data?.requester_company?.description}
                            </Typography.Text>
                            <Flex align={"center"} gap={12}>
                                <TfiCommentsSmiley />
                                <Typography.Text type={"secondary"}>
                                    {data?.rating_comment_by_volunter ||
                                        "Nenhum comentário atribuido"}
                                </Typography.Text>
                            </Flex>
                        </Space>
                    </Card>
                </Col>
                <Col span={12}>
                    <Card
                        title={"Voluntário"}
                        size={"small"}
                        extra={
                            OrderStatusEnum.FINISHED === data?.status &&
                            !data?.rating_by_company
                                ? [
                                      <ModalRating
                                          onFinish={(values) => {
                                              setLoading(true);
                                              api.put(`/orders/${id}`, {
                                                  rating_by_company:
                                                      values.rating,
                                                  rating_comment_by_company:
                                                      values.comment,
                                              })
                                                  .then(({ data }) => {
                                                      setData((old) => ({
                                                          ...old,
                                                          rating_comment_by_company:
                                                              values.comment,
                                                          rating_by_company:
                                                              values.rating,
                                                      }));
                                                  })
                                                  .catch(() => {})
                                                  .finally(() => {
                                                      setLoading(false);
                                                  });
                                          }}
                                      />,
                                  ]
                                : []
                        }
                    >
                        {data?.volunteer?.name ? (
                            <Space
                                style={{ width: "100%" }}
                                direction={"vertical"}
                            >
                                <Flex justify={"space-between"}>
                                    <Flex align={"center"} gap={12}>
                                        <FaUser color={"#c6c6c6"} />
                                        <Typography.Text strong>
                                            {" "}
                                            {data?.volunteer?.name}
                                        </Typography.Text>
                                    </Flex>
                                    {data?.status ===
                                    OrderStatusEnum.FINISHED ? (
                                        <Flex gap={12} align={"center"}>
                                            <Rate
                                                onChange={() => {}}
                                                value={
                                                    data?.rating_by_company || 0
                                                }
                                                disabled
                                            />
                                        </Flex>
                                    ) : null}
                                </Flex>
                                <Flex vertical gap={12}>
                                    <Flex align={"center"} gap={12}>
                                        <FaBusinessTime color={"#c6c6c6"} />
                                        <Typography.Text strong>
                                            {" "}
                                            {data?.provider_company?.name}
                                        </Typography.Text>
                                    </Flex>
                                    <Typography.Text type={"secondary"}>
                                        {" "}
                                        {data?.provider_company?.description}
                                    </Typography.Text>
                                    <Flex align={"center"} gap={12}>
                                        <TfiCommentsSmiley />
                                        <Typography.Text type={"secondary"}>
                                            {" "}
                                            {data?.rating_comment_by_company ||
                                                "Nenhum comentário atribuido"}
                                        </Typography.Text>
                                    </Flex>
                                </Flex>
                            </Space>
                        ) : (
                            <Empty
                                description={
                                    "Atualmente, não temos um voluntário associado a essa atividade."
                                }
                            />
                        )}
                    </Card>
                </Col>
            </Row>
        </Spin>
    );
};
