import React, { useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { GetOneApiReturn, useGetOneApi } from "rk-react-component";
import { PageFormContainer } from "../../../containers/pages/page-form-container/page-form-container";
import { api } from "../../../services/api";
import { RoleModel } from "../../../@types/role";
import { MeContext } from "../../../contexts/me-context/me-context";
import { FormVolunteer } from "../../../containers/forms/form-volunteer/form-volunteer";

export const VolunteerFormPage = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const { role } = useContext(MeContext);
    const { data, loading }: GetOneApiReturn<RoleModel> = useGetOneApi({
        url: "/roles",
        id,
        api,
    });
    return (
        <PageFormContainer
            loading={loading}
            title={"Voluntário"}
            FormComponent={
                <FormVolunteer
                    companyId={role?.allow_model_id}
                    initialValues={data}
                    onFinish={(dataRequest) => navigate("/volunteers")}
                />
            }
        />
    );
};
