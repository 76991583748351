import React from "react";
import { Button, Form, FormProps } from "antd";
import { FaSearch } from "react-icons/fa";

interface FormFilterContainerProps extends FormProps {
  children: React.ReactNode;
}

export const FormFilterContainer = ({
  children,
  ...props
}: FormFilterContainerProps) => {
  return (
    <Form {...props} layout={"inline"}>
      {children}
      <Form.Item>
        <Button
          id={"btn_search"}
          type={"primary"}
          shape={"circle"}
          htmlType={"submit"}
        >
          <FaSearch />
        </Button>
      </Form.Item>
    </Form>
  );
};
