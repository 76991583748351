import React, { useContext, useEffect, useMemo } from "react";
import { useGetAllApi } from "rk-react-component";
import { PageIndexContainer } from "../../../containers/pages/page-index-container/page-index-container";
import { api } from "../../../services/api";
import { TableOrder } from "../../../containers/tables/table-order/table-order";
import { MeContext } from "../../../contexts/me-context/me-context";
import { FormFilterOrder } from "../../../containers/form-filter/form-filter-order/form-filter-order";

export const OrderIndexPage = () => {
    const { role } = useContext(MeContext);
    const fixParams = useMemo(() => {
        if (role.role === "OSC_MANAGER") {
            return {
                requester_company_id: role?.allow_model_id,
            };
        }
        return {};
    }, [role]);
    const { dataList, loading, fetchDataList, pagination, filter } =
        useGetAllApi<any, any>({
            url: "/orders",
            api,
            initialRequest: false,
            fixParams,
            filterKey: "orderIndexPage",
        });

    useEffect(() => {
        fetchDataList({ page: 1, per_page: 10, ...filter });
    }, []);
    console.log(filter);
    return (
        <PageIndexContainer
            title={"Atividades"}
            btnTitle={"Nova Atividade"}
            url={"/orders"}
            FormFilterComponent={
                <FormFilterOrder
                    onFinish={(data) => fetchDataList(data)}
                    initialValues={filter}
                />
            }
            TableComponent={() => (
                <TableOrder
                    dataSource={dataList}
                    loading={loading}
                    loadData={(data) => fetchDataList({ ...filter, ...data })}
                    pagination={pagination}
                />
            )}
        />
    );
};
