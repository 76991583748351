import React from "react";
import "./style.css";
import { TableContainer } from "../table-container/table-container";

interface TableCompanyProps {
  dataSource: any[];
  loading: boolean;
  onEdit?: (record: any) => void;
  pagination?: any;
  loadData?: any;
  onDelete?: (record: any) => void;
}

export const TableDocument = ({dataSource, loading, onEdit, pagination, loadData, onDelete}: TableCompanyProps) => {

    return (
        <TableContainer
            onDelete={onDelete}
            loadData={loadData}
            id={"table_document"}
            loading={loading}
            pagination={pagination}
            onEdit={onEdit}
            columns={[
                {dataIndex: "name", key: "name", title: "Nome"},
                {dataIndex: "type", key: "type", title: "Tipo", render: (_val, record) => record?.config_option?.name},
                {dataIndex: "document", key: "document", title: "Documento", render: (_val, record) => (
                        <a href={record?.path} target={'_blank'}>{record?.path}</a>
                    )},
            ]}
            dataSource={dataSource}
        />
    );
};
