import React from "react";
import { Form, Input } from "antd";
import { FormFilterContainer, FormFilterItem } from "rk-react-component";

interface FormFilterPartyProps {
    onFinish: (data: any) => void;
    initialValues?: any;
}

export const FormFilterUser = ({ onFinish, initialValues }: FormFilterPartyProps) => {
  const [form] = Form.useForm();
  return (
    <FormFilterContainer
      formProps={{ onFinish, initialValues }}
      form={form}
      hiddenShowMore={true}
    >
      {() => (
        <>
          <FormFilterItem name={"name_lk"}>
            <Input placeholder={"Nome"} />
          </FormFilterItem>
          <FormFilterItem name={"email_lk"}>
            <Input placeholder={"Email"} />
          </FormFilterItem>
        </>
      )}
    </FormFilterContainer>
  );
};
