import React from "react";
import { Form, Input, Switch } from "antd";
import { CompanyModel } from "../../../@types/company";
import {
    FormContainer,
    FormItem,
    InputCnpj,
    useFormApi,
} from "rk-react-component";
import { api } from "../../../services/api";

interface FormCompanyProps {
    onFinish: (data: CompanyModel) => void;
    initialValues?: CompanyModel;
    onBack?: () => void;
    fixParams?: Record<any, any>;
    type: "COMPANY" | "OSC";
}

export const FormCompany = ({
  onFinish,
  initialValues,
  onBack,
  fixParams = {},
  type,
}: FormCompanyProps) => {
  const [form] = Form.useForm();
  const { save, loading } = useFormApi<any, any>({
    fixParams: { ...fixParams, type },
    url: "/companies",
    id: initialValues?.id,
    api,
  });
  return (
    <FormContainer
      labelCol={{ span: 24 }}
      onBack={onBack}
      name={"form_company"}
      initialValues={initialValues}
      form={form}
      onFinish={(data) => {
        save(
          data,
          (response) => {
            onFinish(response);
          },
          (e) => console.warn(e),
        );
      }}
      loading={loading}
    >
      <FormItem
        label={"Nome"}
        name={"name"}
        rules={[{ required: true, message: "O Nome é obrigatório." }]}
      >
        <Input placeholder={"Nome"} />
      </FormItem>
      <FormItem
          label={"Descrição"}
          name={"description"}
          rules={[{ required: true, message: "A Descrição é obrigatória." }]}
      >
        <Input.TextArea
            placeholder={"Descrição"}
            showCount={true}
            autoSize={{ minRows: 6 }}
        />
      </FormItem>
      <FormItem
          label={"CNPJ"}
          name={"cnpj"}
          rules={[{ required: true, message: "O CNPJ é obrigatório." }]}
      >
        <InputCnpj/>
      </FormItem>
      <FormItem
        label={"Já tem programa de voluntariado corporativo?"}
        name={"has_volunter_program"}
        valuePropName={"checked"}
      >
        <Switch />
      </FormItem>
      <FormItem
        label={"Possui programa de ESG?"}
        name={"has_esg_program"}
        valuePropName={"checked"}
      >
        <Switch />
      </FormItem>
      <FormItem
        label={"Possui programa de Responsabilidade Social?"}
        name={"has_social_responsibility_policy"}
        valuePropName={"checked"}
      >
        <Switch />
      </FormItem>
      <FormItem label={"Instagram"} name={"instagram"}>
        <Input placeholder={"Instagram"} data-testid={"instagram"} />
      </FormItem>
      <FormItem label={"Facebook"} name={"facebook"}>
        <Input placeholder={"Facebook"} data-testid={"facebook"} />
      </FormItem>
      <FormItem label={"Site"} name={"site"}>
        <Input placeholder={"site"} data-testid={"site"} />
      </FormItem>
      <FormItem label={"Linkedin"} name={"linkedin"}>
        <Input placeholder={"Linkedin"} data-testid={"linkedin"} />
      </FormItem>
    </FormContainer>
  );
};
