import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { PageFormContainer } from "../../../containers/pages/page-form-container/page-form-container";
import { useGetOneApi } from "rk-react-component";
import { FormMedia } from "../../../containers/forms/form-media/form-media";
import { api } from "../../../services/api";

export const MediaFormPage = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { data, loading } = useGetOneApi<any>({ url: "/medias", id, api });
  return (
    <PageFormContainer
      loading={loading}
      title={"Mídias"}
      FormComponent={() => (
        <FormMedia
          initialValues={data}
          onFinish={(dataApi) => navigate(`/medias/${dataApi?.id}`)}
        />
      )}
    />
  );
};
