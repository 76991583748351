import { Tag } from "antd";
import { OrderStatusEnum } from "../../@types/order-status-enum";
import React from "react";

export const TagStatusOrder = ({ status }) => {
    if (status === OrderStatusEnum.PENDING) {
        return <Tag color="gray">Pendente</Tag>;
    }
    if (status === OrderStatusEnum.DRAFT) {
        return <Tag color="gray">Rascunho</Tag>;
    }
    if (status === OrderStatusEnum.CANCELLED) {
        return <Tag color="red-inverse">Cancelado</Tag>;
    }
    if (status === OrderStatusEnum.WAITING_COMPANY) {
        return <Tag color="orange-inverse">Aguardando Aprovação</Tag>;
    }
    if (status === OrderStatusEnum.ONGOING) {
        return <Tag color="blue-inverse">Em Andamento</Tag>;
    }

    if (status === OrderStatusEnum.APPROVED) {
        return <Tag color={"#2a9ba4"}>Aprovado</Tag>;
    }
    if (status === OrderStatusEnum.FINISHED) {
        return <Tag color="green-inverse">Finalizado</Tag>;
    }
    return "-";
};